<template>
  <div class="containerMissionAbout">
    <div class="subContainerMission" style="padding-bottom: 4vh;">
      <h1 class="mainTitle">О компании</h1>
    </div>
    <div class="subContainerMission">
        <img src="../assets/aboutPage/missionAbout.webp"
             alt=""
             v-motion
             :initial="{
                    x: -100,
                    opacity: 0,
                }"
             :enter="{
                    x: 0,
                    opacity: 1,
                    transition: {
                        duration: 300,

                    }
                }"
        >
        <div class="containerText"
             v-motion
             :initial="{
                    x:100,
                    opacity: 0,
                }"
             :enter="{
                    x:0,
                    opacity: 1,
                    transition: {
                        duration: 300,

                    }
                }">
          <h2

          >
            {{$t('AboutPage.mission.missionTitle')}}
          </h2>
          <div class="about-text">
            <p>
              {{$t('AboutPage.mission.missionText-first')}}
            </p>

            <p>
              {{$t('AboutPage.mission.missionText-two')}}
            </p>

            <p>
              {{$t('AboutPage.mission.missionText-three')}}
            </p>
          </div>

        </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "MissionAbout",
  data () {
    return {
    }
  }
}
</script>

<style scoped>
    .mainTitle {
      font-size: 6.5vh;
      text-transform: uppercase;
      color: var(--text);
      font-weight: 100;

    }

    .containerMissionAbout {
      width: 100vw;
      padding-top: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text);
      background: var(--default-bg);
      scroll-snap-align: center;
      flex-direction: column;
    }
    .subContainerMission {
      width: 1000px;
      column-gap: 30px;
      display: flex;
      justify-content: space-between;
      margin-left: -20px;
      
    }
    img {
      width: 460px;
      box-shadow: var(--about-page-box-shadow);
      height: 396px;
      margin: 10px 0;
      border-radius: 5px;
    }
    .about-text {
      font-size: 16px;
      row-gap: 20px;
      margin-top: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
    .containerText {
      display: flex;
      width: 60%;
      flex-direction: column;
      align-items: start;
    }
    .containerText h2 {
      font-size: 48px;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: -1px;
    }


    @media (max-width: 1030px){
      .subContainerMission{
        padding-left: 20px;
        padding-right: 20px;
      } 
    }

    @media (max-width: 1000px){
      .subContainerMission {
        flex-direction: column-reverse;
        align-items: center;
        margin-left: 10px        
      }

      .containerText {
        width: 90vw;
      }

      .containerText h2 {
      font-size: 35px;
      
      }

      img{
        width: 90vw;
        height: 560px;
      }

      
    }

    @media (max-width: 700px){
      img{
        
        height: 800%;
      }
    }

    @media (max-width: 500px){
      /* .containerText {
        width: 90vw;
        
      } */

      img{
        /* width: 340px; */
        height: 300px;
        margin-top: 20px;
      }

      .subContainerMission {
        margin-left: 0px;
      }
    }
</style>
