<template>
   <li          class="container"
                :class="{
                    'hidden-left': index < viewIndex-1,
                    'left': index == viewIndex-1,
                    'middle': index == viewIndex,
                    'right': index == viewIndex+1,
                    'hidden-right': index >= viewIndex+2,
                }"
   >
      <div
          class="image-container"
          v-if="cardImage !== 'no-image' && cardImage != ''"
      >
        <div class="blurImage" :style="{backgroundImage: `url(${cardImage})`}"></div>
        <div class="normalImage" :style="{backgroundImage: `url(${cardImage})`}"></div>
      </div>
      <div
          class="no-image-container"
          v-else
      >

      </div>

      <p class="text-container">
        {{ cardText }}
      </p>
      <CustomButton class="buttom"
                    :link="`/catalog/${category_type.replaceAll(' ', '_')}/${cardName.replaceAll(' ', '_')}?id=` + id"
                    :buttonText="$t('Buttons.more')"
      />

   </li>
</template>

<script>
    export default {

        name: 'CustomCard',

        data() {
            return {

            }
        },

        props: {
            cardText: {
                type: String,
                default: "Default card text",
            },
            cardName: {
                type: String,
                default: "Default card text",
            },

            cardImage: {
                default: 'no-image',
            },

            isClicked: {
                type: Boolean,
                default: false
            },
            category_type: {
                type: String
            },

            index: {
                default: 0,
            },

            viewIndex: {
                default: 0,
            },
            id: {
                default: 0
            }
        },

        methods: {
            getImagePath(filename) {
                return require('../../assets/' + filename);
            }
        }
    }
</script>

<style scoped>
    button {
      border: none;
      background: none;
      outline: none;
    }
    .container {
        width: 310px;
        height: 376px;

        position: absolute;

        margin-left: calc(310px/-2);

        background: var(--card-bg);

        border-radius: 12px;

        border-style: solid;
        border-width: 1px;
        border-image: 
            linear-gradient(
            to bottom, 
            rgba(20, 20, 20, 0.1),
            #ccc, 
            rgba(20, 20, 20, 0.1)
            ) 1 100%;

        box-shadow: var(--card-box-shadow);
        transition: 0.3s;
        
        padding-top: 0px;
        padding-bottom: 24px;
    }

    .hidden-left {
        transform: translateX(-670px) scale(0.05);
        opacity: 0;
    }

    .hidden-right {
        transform: translateX(670px) scale(0.05);
        opacity: 0;
    }

    .left {
        transform: translateX(-370px) scale(0.85);
        opacity: 0.5;
        filter: blur(2px);
    }

    .middle {
        transform: translateX(0px) scale(1);
        opacity: 1;
        z-index: 5;
    }

    .right {
        transform: translateX(370px) scale(0.85);
        opacity: 0.5;
        filter: blur(2px);
    }
    .button_custom_card {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

    }
    .image-container, .no-image-container {
        width: 100%;
        height: 280px;

        overflow: hidden;

        position: relative;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .no-image-container {
        /* background: red; */
        background-image: url('@/assets/noImage.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 32px;
    }

    .blurImage {
        width: 100%;
        height: 100%;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        /* opacity: 0.2; */
        position: absolute;
        filter: blur(20px);
        opacity: 0.6;
    }

    .normalImage {
        width: calc(100% - 15px);
        height: calc(100% - 15px);

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;

        z-index: 2;
    }

    .text-container {
        width: 80%;
        height: 130px;


        bottom: 0px;
        

        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;

        color: var(--text);

        font-size: 16px;
    }

    @media (max-width: 1150px){
  .left{
    opacity: 0;
  }

  .right{
    opacity: 0;
  }
}

    @media (max-width: 420px){
    .container{
        width: 270px;
        height: 316px;
        margin-left: calc(270px/-2);
        
        
    }

    

    .image-container{
        width: 240px;
        height: 240px;  
    }
    }
</style>
