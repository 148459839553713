<script setup>
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n({useScope: 'global'});
</script>

<template>
    <div class="container" @mousemove="moveBackground($event)">
        <div class="decorate-circles"></div>
        <div 
            class="decorate-gradient" 
            v-bind:style="{ marginTop: backgroundX*5 + 'px',
                            marginLeft: backgroundY*5 + 'px' }">
        </div>
        <div class="black-gradient"></div>
        <div 
            v-bind:style="{ backgroundPositionX: backgroundX + 'px',
                            backgroundPositionY: backgroundY + 'px',
                            backgroundImage: `url(${require('../assets/backgrounds/' + backgrounds[backgroundNumber])})`,
                        }"
            class="slider-image">
        </div>
        


        <div class="content-container">   
            <div class="main-text-container">
            <div class="text-container">
            <div class="h1-container">
                <p>{{ $t('FullscreenSlider.inovations') }}</p>
                <p>{{ $t('FullscreenSlider.experience') }}</p>
                <p>{{ $t('FullscreenSlider.result') }}</p>
            </div>
        </div>
        <div class="slider-text-container" @mouseenter="autoSkip = false" @mouseleave="autoSkip = true">

            <button
            class="slider-text"
            v-for="(texts, index) in texts_ru"
            :class="{'picked-slider-text':index == backgroundNumber}"
            @click="backgroundNumber = index">
            {{ $store.state.lang == 'ru-RU' ? texts_ru[index] : texts_en[index]}}
            </button>

        </div>
        
        </div>
            <div class="dots-container">
                <div class="dots" @mouseenter="autoSkip = false" @mouseleave="autoSkip = true">
                    <div 
                        class="dot" 
                        v-for="(background, index) in backgrounds"
                        :key="index"
                        @click="backgroundNumber = index"
                        :class="{'picked-dot':index == backgroundNumber}">
                    </div>
                </div>
            </div>
       
        </div>

    
    </div>
</template>

<script>
export default {
    name:'FullscreenSlider',
    data(){
        return {
            movewait: undefined,
            backgroundX: 0,
            backgroundY: 0,
            autoSkip: true,
            backgroundNumber: 0,
            backgrounds: ['background1.webp', 'background2.webp', 'background3.webp', 'background4.webp'],

            texts_ru: ['Разработка и эксплуатация электронных устройств',
                    'Внедрение искусственного интеллекта',
                    'Проектирование и обслуживание систем автоматизации',
                    'Создание устройств передачи данных для горнодобывающей промышленности'],

            texts_en: ['Development and operation of electronic devices',
                    'Implementation of artificial intelligence',
                    'Design and maintenance of automation systems',
                    'Creation of data communication devices for the mining industry']
        }
        
    },

    methods: {
        moveBackground(e){

            // this.backgroundX = (window.innerWidth/2 - e.clientX)/50;
            // this.backgroundY = (window.innerHeight/2 - e.clientY)/50;

            if(typeof this.movewait != 'undefined'){
                    clearTimeout(this.movewait);
                }

            this.movewait = setTimeout(function(){
                // this.backgroundX = (window.innerWidth/2 - e.clientX)/50;
                // this.backgroundY = (window.innerHeight/2 - e.clientY)/50;
                this.changeMousePosition((window.innerWidth/2 - e.clientX)/30, (window.innerHeight/2 - e.clientY)/30)
                // console.log();
            }.bind(this),50);

            // console.log(e);
        },

        changeMousePosition(x, y){
            this.backgroundX = x;
            this.backgroundY = y;
        },

        changeBackground(backgroundNumber){

        }
    },

    mounted() {
        setInterval(() => {
            if(this.autoSkip){
                this.backgroundNumber++   
                if(this.backgroundNumber >= this.backgrounds.length){
                    this.backgroundNumber = 0;
                }
            }
        }, 3500)
    },

    destroyed() {

    }
    
}
</script>

<style scoped>
    .container  {
        width: 100%;
        height: 100vh;
        overflow: hidden;

        display: flex;

    }

    .black-gradient {
        width: 100vw;
        height: 100vh;

        position: absolute;

        background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(2,2,2,1) 100%);
        background-position-x: 200px;

        pointer-events: none;

        z-index: 2;
    }

    @keyframes image-smoother {
        0% {
            transform: translateX(50px) scale(1.1);
            opacity: 0;
        }

        100% {
            transform: translateX(0px) scale(1.1);
            opacity: 1;
        }
    }
    .slider-image {
        width: 100vw;
        height: 100vh;
        animation: image-smoother 1s ease-in-out;
        background-image: url(@/assets/backgrounds/background1.webp);

        background-position: right;
        background-repeat: no-repeat;

        transform: scale(1.1);
        pointer-events: none;
        box-sizing: border-box;
        position: absolute;
        background-size: cover;
        transition: 0.5s;
        background-attachment: fixed;
        background-size: cover;
    }

    .slider-text-container {
        color: white;

        z-index: 7;
        

        display: flex;
        flex-direction: column;

        width: 600px;
        
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent; 
        
        width: 100%;

        align-items: center;

    }
    button {
      background: none;
      border: none;
      outline: none;
      text-align: start;
    }
    .slider-text {
        width: 1300px;
        font-size: 24px;
        color: #6b6b6b;
        overflow: hidden;
        transition: 0.5s;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent; 
    }

    .slider-text:hover {
        color: #ccc;
    }

    .picked-slider-text {
        width: 1300px;
        font-size: 30px;
        color: #ffffff;
        margin-top: 10px;
        margin-bottom: 0px;
        padding-left: 10px;
        border-left: solid 4px white;
    }

    .decorate-gradient {
        width: 500px;
        height: 500px;

        position: absolute;
        z-index: 3;

        /* border: solid 1px red; */
        top: -10%;
        left: -10%;

        /* background: radial-gradient(circle, rgba(29,113,184,0.4) 0%, rgba(0,212,255,0) 80%); */
        background-color: rgba(29,113,184,0.4);
        filter: blur(100px);

        border-radius: 100%;

        background-position: center;
        background-repeat: no-repeat;

        transition: 1s;
    }

    

    @keyframes left-opacity {
        0% {
            transform: translateX(-400px);
            opacity: 0;
        }

        100% {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    .content-container{
        display: flex;
    height: 100%;
    flex-direction: column;
    
    align-items: center;
    justify-content: space-around;
        
    }

    .main-text-container{
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        
        
    }
    .h1-container {
        width: 1300px;
        /* border: solid 1px blue; */
        /* padding-left: 96px; */
        overflow: visible;
        column-gap: 20px;
        display: flex;
        flex-direction: row;
        p {
          text-transform: uppercase;
          opacity: 0;
          animation: left-opacity 0.8s ease-in-out forwards;

          font-size: 74px;
          color: white;
          /*line-height: .88;*/

          overflow: hidden;
        }

        p:nth-child(2) {
          animation-delay: .2s;
        }

        p:nth-child(3) {
          animation-delay: .4s;
        }
    }
    .text-container {
       

        width: 100vw;
        height: auto;

        display: flex;
        
        justify-content: center;

        /* border: solid 1px red; */

        padding-bottom: 70px;

        /*line-height: 1.5;*/

        overflow: hidden;

        pointer-events: none;

        z-index: 3;

    }



    .dots-container{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        
        
    }

    .dots {
        
        z-index: 9;

        width: 62px;
        
        
        display: flex;
        justify-content: space-around;
        align-items: center;

        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
-webkit-tap-highlight-color: transparent; 

        /* pointer-events: none; */
    }

    .picked-dot{
        width: 14px !important;
        height: 14px !important;
        
        
    }
    .dot {
        width: 8px;
        height: 8px;

        border-radius: 100%;
        color: #ffffff;
        background-color: white;
        cursor: pointer;
        overflow: hidden;
        transition: 0.5s;
    }

    @keyframes top-down-opacity {
        0% {
            transform: translateY(-300px);
            opacity: 0;
        }

        100% {
            transform: translateZ(0px);
            opacity: 1;
        }
    }

    .decorate-circles {
        animation: top-down-opacity .8s ease-in-out forwards;

        opacity: 0;
        top: 0;
        left: 0;
        width: 500px;
        height: 500px;
        background-repeat: no-repeat;
        
        position: absolute;

        background-image: url(@/assets/backgrounds/decorate_circles.svg);

        z-index: 3;
    }

   

    @media (max-width: 1320px){

        .main-text-container{
            justify-content: center;
            align-items: center;
            padding-top: 0px;
            
            
        }

        .content-container{
            height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 200px;
        
    }
        .h1-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            
        }

        .slider-image {
            background-position-x: 50% !important;
            
        }

        p {
            font-size: 8vw !important;
        }

        .slider-text {
            width: 98%;
            text-align: center;
            font-size: 20px;
            color: #6b6b6b;
            
            transition: 0.5s;
            cursor: pointer;

            
            
        }

        .slider-text-container {
            width: 80%;
            text-align: center;
            font-size: 16px;
            color: #6b6b6b;
            row-gap: 5px;
            transition: 0.5s;
            cursor: pointer;

             

        } 

        .container{
            align-items: center;
            justify-content: center;
        }

        .picked-slider-text {
            font-size: 20px;
            color: white;
            border: 0;
        }

        

        
        .dots{
            
            
            margin-bottom: 100px;
        }
        
    }

    @media (max-width: 720px){
        p {
            font-size: 12vw !important;
        }

        .container{
            justify-content: center;
        }

        .content-container{
            height: 110%;
        }

        .slider-text-container {
            height: 50%;
            row-gap: 10px;
        }

        .slider-text{
            color: #b6d5e5;
            /* opacity: 0.5; */
        }

        .picked-slider-text {
            font-size: 22px;
            border: 0;
            opacity: 1;
            color: #ffffff;


        }
        

        .dots-container{
            margin-top: -100px;
        }

        .slider-image{
            background-position-x: 100% !important;

        }

        .text-container{
            height: 280px !important;
        }

       
    }

    @media (max-width: 500px){
        .picked-slider-text {
            font-size: 24px;
            border: 0;
        }

        .slider-text-container {
            height: 80%;
            row-gap: 10px;
        }

        .slider-text{
            font-size: 20px;
        }

        p {
            font-size: 12vw !important;
        }

        .content-container{
            margin-top: 150px;
        }

        .text-container{
            height: 240px !important;
        }
    }

    @media (max-width: 420px){
        .picked-slider-text {
            transform: scale(1.2);            
            border: 0;

        }

        .slider-text-container {
            height: 80%;
            row-gap: 10px;
        }

        .slider-text{
            font-size: 16px;
        }

        p {
            font-size: 10vw !important;
        }

        .content-container{
            margin-top: 150px;
        }

        .text-container{
            height: 200px !important;
        }
    }

   

</style>