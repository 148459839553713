<template>
  <h2 class="title">
    {{$store.state.lang === 'ru-RU' ? 'ПОДБОР ВАКАНСИЙ' : 'JOB SELECTION'}}
  </h2>
  <div class="container" id="vacancySelection">
      <div class="sub-container">
        <div class="container-vacancy-selection-first">
          <div class="city">
            <p class="title-vacancy-selection">Город</p>
            <DropBox :selected="city"
                     :options="arrayCity"
                     :style="{width: `300px`}"
                     :isFullHover="true"
                     :heightTitle="`45px`"
                     :widthOption="`298px`"
                     @select="optionSelectCity"
                     @click="selectCity(city)"
            />
          </div>
          <div class="type-job">
            <p class="title-vacancy-selection">Тип работы</p>
            <DropBox :selected="typeJob"
                     :options="arrayTypeJob"
                     :style="{width: `300px`}"
                     :isFullHover="true"
                     :heightTitle="`45px`"
                     :widthOption="`298px`"
                     @select="optionSelectTypeJob"
            />
          </div>
          <div class="payday">
            <p class="title-vacancy-selection">Заработная плата</p>
            <div class="container-input">
              <input class="input-custom"
                     type="text"
                     v-model="salary_min"
                     placeholder="от 10000"
              >

              <input class="input-custom"
                     type="text"
                     v-model="salary_max"
                     placeholder="до 15000"
              >
            </div>
          </div>
        </div>

        <div class="container-vacancy-selection-second">
          <div class="type-payday">
            <p class="title-vacancy-selection">Тип заработной платы</p>
            <DropBox :selected="typePayday"
                     :options="arrayTypePayday"
                     :isFullHover="true"
                     :style="{width: `300px`}"
                     :heightTitle="`45px`"
                     :widthOption="`298px`"
                     @select="optionSelectTypePayday"
            />
          </div>
          <div class="busyness">
            <p class="title-vacancy-selection">Занятость</p>
            <DropBox :selected="busyness"
                     :options="arrayBusyness"
                     :isFullHover="true"
                     :style="{width: `300px`}"
                     :heightTitle="`45px`"
                     :widthOption="`298px`"
                     @select="optionSelectBusyness"
            />
          </div>
          <div class="remote-work-only">
            <label class="switch">
              <input type="checkbox" v-model="remote_work" class="checkbox">
              <span class="slider round"></span>
            </label>
            <p class="title-only-remote-work">Только удалённая работа</p>
          </div>

        </div>
      </div>
    <CustomButtonFunc :button-text="'Применить'"
                      @click="handleFilter"
                      :style="{textTransform: `none`, fontSize: '25px', width: `85%`, maxWidth: '384px'}"
    />
  </div>
</template>

<script>
import DropBox from "@/components/ui/DropBox.vue";
import CustomButtonFunc from "@/components/ui/CustomButtonFunc.vue";
import {HTTP} from "@/plugins/http-common";

export default {
  name: 'VacancySelection',
  components: {CustomButtonFunc, DropBox},
  data() {
    return {
      city: '',
      typeJob: '',
      typePayday: '',
      busyness: '',
      salary_min: "",
      salary_max: "",
      remote_work: 0,
      arrayCity: [],
      arrayTypeJob: [],
      arrayTypePayday: [],
      arrayBusyness: []
    }
  },
  props: {
    page: {},
  },
  methods: {
    optionSelectCity(select) {
      this.city = select
    },

    handleFilter() {
      if (this.city === ''
          && this.typeJob === ''
          && this.typePayday === ''
          && this.busyness === ''
          && this.salary_max === ''
          && this.salary_min === ''
          && this.remote_work === 0) {
        this.$emit('filterVacancyClear')
      } else {
        this.$watch(
            () => this.page,
            async () => {
              HTTP.post('/vacancies/filter_vacancy', {
                limit: 4,
                skip: (this.page - 1) * 4,
                city: this.city,
                work_type: this.typeJob,
                salary_min: this.salary_min,
                salary_max: this.salary_max,
                work_time: this.busyness,
                salary_type: this.typePayday,
                remote_work: this.remote_work ? 1 : 0
              }, {
                headers: {
                  'accept': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded',
                }
              }).then(response => {
                for (let i = 0; i < response.data.data.length; i++) {
                  this.$emit('filtersVacancy', response.data.data)
                }


              }).catch(err => {
                this.$emit('filtersVacancyNone')
              })
            }
        )
        HTTP.post('/vacancies/filter_vacancy', {
          city: this.city,
          work_type: this.typeJob,
          salary_min: this.salary_min,
          salary_max: this.salary_max,
          work_time: this.busyness,
          salary_type: this.typePayday,
          remote_work: this.remote_work ? 1 : 0

        }, {
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        }).then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.$emit('updateTotalPage', response.data.pages)
          }

        }).catch(err => {
          this.$emit('filtersVacancyNone')
        })
        HTTP.post('/vacancies/filter_vacancy', {
          limit: 4,
          skip: (this.page - 1) * 4,
          city: this.city,
          work_type: this.typeJob,
          salary_min: this.salary_min,
          salary_max: this.salary_max,
          work_time: this.busyness,
          salary_type: this.typePayday,
          remote_work: this.remote_work ? 1 : 0

        }, {
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        }).then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.$emit('filtersVacancy', response.data.data)
          }

        }).catch(err => {
          this.$emit('filtersVacancyNone')
        })

      }
    },

    optionSelectTypeJob(select) {
      this.typeJob = select
    },

    optionSelectTypePayday(select) {
      this.typePayday = select
    },

    optionSelectBusyness(select) {
      this.busyness = select
    },

    selectCity(city) {

    }
  },
  mounted() {
    try {
      HTTP.get(`/vacancies/get_city`).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          this.arrayCity[i] = response.data.data[i].city
        }
      }).catch(err => {

      })
      HTTP.get(`/vacancies/get_worktime`).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          this.arrayBusyness[i] = response.data.data[i].work_time
        }
      }).catch(err => {

      })
      HTTP.get(`/vacancies/get_worktype`).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          this.arrayTypeJob[i] = response.data.data[i].work_type
        }
      }).catch(err => {

      })
      HTTP.get(`/vacancies/get_salary_type`).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          this.arrayTypePayday[i] = response.data.data[i].salary_type
        }
      }).catch(err => {

      })
    } catch (e) {

    }

    // this.city = this.arrayCity[0]
    // this.typeJob = this.arrayTypeJob[0]
    // this.typePayday = this.arrayTypePayday[0]
    // this.busyness = this.arrayBusyness[0]
  }

}
</script>

<style scoped>
.title {
  padding-top: 50px;
}
.container {
  padding: 0 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  row-gap: 25px;
  flex-direction: column;
  color: var(--text);
}
.container-vacancy-selection-first {
  display: flex;
  width: 1000px;
  column-gap: 25px;
  row-gap: 25px;
  align-items: center;
}
.title-only-remote-work {
  font-size: 18px;
  font-weight: 400;
}
.title-vacancy-selection {
  font-size: 20px;
  font-weight: 600;
}
.container-vacancy-selection-second {
  display: flex;
  width: 1000px;
  column-gap: 25px;
  align-items: center;
}
@media (max-width: 1000px) {
  .container-vacancy-selection-first {
    flex-direction: column;
  }
  .container-vacancy-selection-second {
    flex-direction: column;
  }
}
.remote-work-only {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-top: 25px;
}
.container-input {
  display: flex;
  column-gap: 15px;
}
.input-custom {
  outline: none;
  padding: 5px 13px;
  border-radius: 15px;
  border: 1px solid var(--border-color);
  color: var(--text);
  height: 37px;
  width: 110px;
  font-size: 18px;
  font-weight: 400;
  background: var(--about-bg);

}
.city {
  display: flex;
  flex-direction: column;
}
.type-job {
  display: flex;
  flex-direction: column;
}
.payday {
  display: flex;
  row-gap: 5px;
  flex-direction: column;
}
.title {
  font-size: 48px;
  padding-bottom: 30px;
  color: var(--text);
  text-align: center;
  font-family: geometria_extralight;
  text-transform: uppercase;
}
.switch {
  display: inline-block;
  height: 29px;
  position: relative;
  width: 50px;
}

.switch .checkbox {
  display:none;
}

.slider {
  border: 1px solid var(--border-color);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  outline: none;
  transition: .4s;
}

.slider:before {
  background-color: var(--border-color);
  bottom: 4px;
  content: "";
  height: 19px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 18px;
}

.checkbox:checked + .slider {
  background-color: var(--blue);
}

.checkbox:checked + .slider:before {
  transform: translateX(22px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>