<template>
  <div class="container-vacancy">
    <Header style="position: relative" :theme="$store.state.theme === 'light' ? 'dark' : 'light'"/>
    <Breadcrumbs 
            :path="[
                {name: 'Главная', url: '/'},
                {name: 'Вакансии', url: '/vacancy'},
                {name: 'Вакансия', url: ''},
            ]"
        />
    <div class="container-parser-321" id="upToContainerVacancy" ref="UpToTop" style="margin-top: 60px;">
      <ParserVacancyFull :data="vacancy"/>
      <Parser :header="header"/>
    </div>

    <div class="other-vacation">
      <h2 class="title">
        {{$store.state.lang === 'ru-RU' ? 'ДРУГИЕ ВАКАНСИИ' : 'OTHER VACANCIES'}}
      </h2>
      <div class="container-parser-vacancy">
        <div v-for="(element, index) in otherVacancy"
             class="sub-container-parser-vacancy"
        >
          <ParserVacancy :data="otherVacancy"
                         :index="index"
                         v-if="vacancyID != element.id"
          />
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Parser from "@/components/Parser/Parser.vue";
import ParserVacancyFull from "@/components/Parser/ParserVacancyFull.vue";
import ParserVacancy from "@/components/Parser/ParserVacancy.vue";
import {HTTP} from '@/plugins/http-common'
import Breadcrumbs from "@/components/ui/Breadcrumbs.vue"
import {useHead} from "@vueuse/head";



export default {
  name: 'Vacancy',
  components: {ParserVacancy, ParserVacancyFull, Parser, Footer, Header,Breadcrumbs},
  data() {
    return {
      vacancy: "",
      otherVacancy: [],
      header: []
    }
  },
  props: {
    city: {

    },
    work: {

    },
    vacancyID: {
      default: '1'
    }
  },
  created() {
  },
  mounted() {
  try {
    HTTP.get(`/vacancies/get_vacancies_by_id/${this.vacancyID}`).then(response => {
      this.vacancy = response.data.data[0]
      this.header = JSON.parse(response.data.data[0].body)

      useHead({
        title: response.data.data[0].title,
        meta: [
          {
            name: `description`,
            content: JSON.parse(response.data.data[0].body)[0][1]
          },
          {
            name: `keywords`,
            content: ''
          }
        ]

      })
      this.$watch(
          () => this.vacancyID,
          async () => {
            this.vacancy = ""
            this.header = []
            HTTP.get(`/vacancies/get_vacancies_by_id/${this.vacancyID}`).then(response => {
              this.vacancy = response.data.data[0]
              this.header = JSON.parse(response.data.data[0].body)
            })
            document.getElementsByClassName('container-vacancy')[0].scrollTop = 0
          }
      )
    }).catch(err => {
      // console.log('Ошибка в запросе', err);
    })

    HTTP.get(`/vacancies/get_all_vacancies/0/4`).then(response => {
      for (let i = 0; i < response.data.data.length; i++) {
        this.otherVacancy = response.data.data
      }
    }).catch(err => {

      })

  } catch (e) {

  }

  },
}
</script>

<style scoped>
.container-vacancy {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}
.title {
  font-size: 48px;
  padding-bottom: 30px;
  color: var(--text);
  text-align: center;
  font-family: geometria_extralight;
  text-transform: uppercase;
}
.sub-container-parser-vacancy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.container-parser {
  padding-top: 50px;
}
.container-parser-vacancy {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.other-vacation {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  background-color: var(--about-bg);
}
</style>