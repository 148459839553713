<template>
  
<div v-if="arrayElement.length !== 0">
  <div class="container" :style="isDarkTheme ? {background: 'var(--about-bg)'} : {background: 'var(--default-bg)'}"
       v-show="arrayElement.length > 0">
    <div class="sub-container">

      <div class="sub-container-header">
        <h1 class="mainTitle">
          {{ title }}
        </h1>
      </div>

      <div class="sub-container-body">

        <div class="info-block" v-for="(element, index) in (arrayElement.length !== 0 ? arrayElement : 1)">
          <img v-if="user == 'admin'" class="delete-icon" @click="deletePDF(element)" src="@/assets/icons/delete-icon.svg">
          <div class="image" 
               :style="{backgroundImage: `url(${require('../assets/pdf.svg')})`}"></div>
          <div class="container-text">
            <p class="text-element" v-if="element !== 1">
              {{ type === "pdf" ? JSON.parse(element.title).title_ru : JSON.parse(element.device).title_ru }}
            </p>
            

          </div>
          <CustomLinkDownload
              @click="setClicked(index)"
          />
        </div>
        
  <div class="add-container" @click="() => this.exitState = true"  v-if="user == 'admin'"> 
    <img   
        src="@/assets/icons/add_product.svg"  
        alt="" 
        class="add-icon"
    >
  </div> 
 
      </div>
    </div>

  </div>
  <div v-show="arrayElement.length === 0"></div>
  <ModalSafety :clicked="isClicked" :title="arrayElement.length !== 0 && indexElement !== -1 ?
                                            type === 'pdf' ?
                                            JSON.parse(arrayElement[indexElement].title).title_ru :
                                            JSON.parse(arrayElement[indexElement].device).title_ru :
                                            ''"
               @getClicked="getClicked"
               @download="download"
               :manyRequests="manyRequests"
  />
    
</div>
<div class="sceleton-container" v-else >
  <div class="sceleton-sub-container-title"></div>
  <div class="sceleton-sub-container-comp">
    <div class="sceleton-sub-container">
    </div>
    <div class="add-container" @click="() => this.exitState = true"  v-if="user == 'admin'">
      <img
          src="@/assets/icons/add_product.svg"
          alt=""
          class="add-icon"
      >
    </div>
  </div>

</div>

<WorkerCreator v-if="exitState == true">
  <AddSafetyModal  @exit="exit" :title="title"></AddSafetyModal>
                    
</WorkerCreator>
   
</template>

<script>

import CustomLinkDownload from "@/components/ui/CustomLinkDownload.vue";
import ModalSafety from '@/components/ui/ModalSafety.vue'
import {HTTP} from "@/plugins/http-common";
import WorkerCreator from "@/pages/AdminPages/WorkerCreator.vue";
import AddSafetyModal from "./AddSafetyModal.vue";



export default {
  name: "documentationAboutPage",
  components: {
    CustomLinkDownload,
    ModalSafety,
    WorkerCreator,
    AddSafetyModal
},
  props: {
    title: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "pdf"
    },

    arrayElement: {
      type: Array,
      default: []
    },

    isDarkTheme: {
      type: Boolean,
      default: false
    },


    id: {}
  },
  data() {
    return {
      isClicked: false,
      indexElement: -1,
      certificate: "",
      pdf: "",
      user: 'user',
      exitState: false,
      manyRequests: false, 


    }
  },
  methods: {
    setClicked(index) {
      this.isClicked = true
      this.indexElement = index
    },
    exit(){
      this.exitState = false
    },
    getClicked() {
      this.isClicked = false
      this.manyRequests = false
    },
    
    download() {
      if (this.id !== "") {
        if (this.type === "pdf") {
          HTTP.get(`/document/get_document_by_id/${this.id[this.indexElement]}`).then(response => {
            const a = document.createElement('a');
            a.href = response.data.pdf
            a.download = JSON.parse(this.arrayElement[this.indexElement].title).title_ru + ".pdf";
            document.body.appendChild(a);
            a.click();
            a.remove();
          }).catch(err => {
            console.log(err.response.status);
            if (err.response.status == 429) {
              this.manyRequests = true
              this.isClicked = true
            }

          })
        } else if (this.type === "certificate") {
          HTTP.get(`/certificate/get_certificate_by_id/${this.id[this.indexElement]}`, {
            headers: {
              "accept": "application/json"
            }
          }).then(response => {
            const a = document.createElement('a');
            a.href = response.data.pdf
            a.download = JSON.parse(this.arrayElement[this.indexElement].device).title_ru + ".pdf";
            document.body.appendChild(a);
            a.click();
            a.remove();
          }).catch(err => {
            console.log(err);
            if (err.response.status == 429) {
              this.manyRequests = true
              this.isClicked = true

            }
          })
        }

      }

    },

    deletePDF(element) {
      if (this.type == 'pdf') {
        HTTP.delete('/document/delete_document/' + Number(element.id),
        {headers: {
            'Content-Type': 'application/json'
        }})
        .then(response => {
          window.location.href = "/safety";

        }).catch((err) => {
            console.log(err);
        })
        console.log(element.id);
    
      }else{
        HTTP.delete('/certificate/delete_certificate/' + Number(element.id),
        {headers: {
            'Content-Type': 'application/json'
        }})
        .then(response => {
          window.location.href = "/safety";

        }).catch((err) => {
          console.log(err);
        })
      }
      
    }

  },

  mounted() {
    // Очищаем массив при маунте страницы
    
    if(localStorage.user) this.user = localStorage.user;

  },

  updated() {
    // console.log(this.sefetyPdf)
  }


}
</script>

<style scoped>

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.mainTitle {
  font-size: 6.5vh;
  text-transform: uppercase;
  color: var(--text);
  font-weight: 100;

}

.sceleton-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sceleton-sub-container {
  background: var(--sceleton-card);
  border-radius: 5px;
  background-size: 200% 100% !important;
  animation: 1.5s shine linear infinite;

  height: 340px;
  width: 20%;
  margin-bottom: 70px;
  box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);

}

.sceleton-sub-container-comp {
  display: flex;
  justify-content: center;
  width: 100%;
}

.sceleton-sub-container-title {
  background: var(--sceleton-card);
  border-radius: 5px;
  background-size: 200% 100% !important;
  animation: 1.5s shine linear infinite;

  height: 60px;
  width: 40%;
  margin-top: 20px;
  margin-bottom: 70px;
  box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);

}

.text-element {
  word-break: break-word;
  height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.container {
  width: 100vw;
  display: flex;
  justify-content: center;

  align-items: center;
  color: var(--text);
  /* background: var(--default-bg); */
  padding-bottom: 30px;
}

.sub-container {
  width: 1000px;
  column-gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-container-header {
  width: 100%;
  min-height: 70px;
  height: 100%;
  max-height: 150px;
  display: flex;
  justify-content: center;
  padding-top: 2vh;
}

.sub-container-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  justify-content: center;
}

.container-text {
  padding: 20px 0;
  height: 60px;
  width: 80%;
}

.info-block {
  width: 30%;
  min-width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.image {
  background-size: contain;
  height: 120px;
  width: 160px;
  margin-top: 45px;
  background-position: center;
  /*background-size: 100% 100%;*/
  background-repeat: no-repeat;
}

.add-container {
  width: 240px;
    height: 280px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    justify-content: center;
    background: #E9EDF0;
  
    border-radius: 22px;
    cursor: pointer;
    
    padding-top: 0px;
    padding-bottom: 24px;
}

.delete-icon{
  cursor: pointer;
  margin-left: 240px;
  margin-top: 2vh;
}

h2 {
  font-size: 48px;
  color: var(--text);
  font-weight: 500;
  text-align: center;
  overflow: hidden;
}

@media (max-width: 950px) {

}

</style>
