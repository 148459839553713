<template>
    <div class="aboutPageContainer">
        <Header
                style="position: relative"
                :theme="$store.state.theme == 'light' ? 'dark' : 'light'"
        />

        <!--    <div>-->
        <MissionAbout/>
        <!--    </div>-->

        <div>
            
            <HistoryAbout/>
            <AboutHistoryBackground/>
            <Circle class="Circle" :circleType="'left'"/>

            <AboutProductHistory/>
            <!--        <Circle :circleType="'right'"/>-->
            <div :style="{backgroundColor: `var(--about-bg)`}" ref="team">
                <CardCarousel
                        :title=" $store.state.lang == 'ru-RU' ? 'Наша команда' : 'Our team'"
                        :description="''"
                        link="team"
                        :cards="Team"
                        :contacts="true"
                />
            </div>

<!--            <CertificateAboutPage :array-element="certificate"/>-->

<!--            <DocumentationAboutPage :style="{backgroundColor: `var(&#45;&#45;default-bg)`}"-->
<!--                                    pageLink="industrialSafety"-->
<!--                                    @checkClicked="onCheckClicked"-->
<!--                                    :is-download="false"-->
<!--                                    :title="$store.state.lang === 'ru-RU' ? industrialSafetyRU : industrialSafetyEN"-->
<!--                                    :array-element="industrialSafety"/>-->

        </div>

        <Footer/>

    </div>


</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import MissionAbout from "@/components/MissionAbout.vue";
import HistoryAbout from "@/components/HistoryAbout.vue";
import AboutHistoryBackground from "@/components/AboutHistoryBackground.vue";
import AboutProductHistory from "@/components/AboutProductHistory.vue";
import CardCarousel from "@/components/CardCarousel.vue";
import {HTTP} from "@/plugins/http-common";
import DocumentationAboutPage from "@/components/DocumentationAboutPage.vue";
import CertificateAboutPage from "@/components/CertificateAboutPage.vue";
import ourTeam from "@/pages/AdditionalPages/OurTeam.vue";
import {useHead} from "@vueuse/head"
import store from "@/store";

export default {
    computed: {
        ourTeam() {
            return ourTeam
        }
    },
    components: {
        CertificateAboutPage,
        DocumentationAboutPage,
        AboutProductHistory,
        AboutHistoryBackground,
        HistoryAbout,
        MissionAbout,
        Header,
        Footer,
        CardCarousel
    },
    data() {
        return {
            Team: [],
            documentation: [],
            industrialSafety: [],
            watchComponent: false,
            certificate: [],
            linkDocumentation: '',
            checkClicked: '',
            documentationTitleRU: 'Документация',
            documentationTitleEN: 'Documentation',
            industrialSafetyRU: "ПРОМБЕЗОПАСНОСТЬ",
            industrialSafetyEN: "INDUSTRIAL SAFETY",
        }
    },

    methods: {
        onCheckClicked(date) {
            this.checkClicked = date
        },
        getCommands() {
            HTTP.get(`/command/get_all_command`).then(response => {
                const teamImages = []
                for (let i = 0; i < response.data.data.length; i++) {
                    this.Team[i] = {
                        img: JSON?.parse(response.data.data[i].image).image,
                        textRU: JSON?.parse(response.data.data[i].body).body_ru[0][1],
                        textEN: JSON?.parse(response.data.data[i].body)?.body_eng[0][1],
                        descriptionRU: JSON?.parse(response.data.data[i].body).body_ru[1][1],
                        descriptionEN: JSON?.parse(response.data.data[i].body).body_eng[1][1],
                        email: JSON?.parse(response.data.data[i].body).body_ru[2][1],
                        phone: JSON?.parse(response.data.data[i].body).body_ru[3][1],
                    }
                }

                for (let index = 0; index < response.data.data.length; index++) {

                    const imagePath = this.Team[index].img;
                    const formData = new FormData();
                    formData.append('image', imagePath);
                    HTTP.post('/get_image/download_image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    responseType: 'blob'
                    }).then(responseImage => {
                        const url = URL.createObjectURL(responseImage.data);  
                        this.Team[index].img = url
                                
                    }).catch(error => {
                        console.error('Ошибка при загрузке изображения:', error);
                    });
                }

            })

        }
    },


    created() {
        HTTP.get(`/certificate/get_certificate_number/4`).then(response => {
            for (let i = 0; i < response.data.length; i++) {
                this.certificate[i] = {
                    // image: '@/assets/certificate.jpeg',
                    count: response.data[i].count,
                    titleRU: JSON?.parse(response.data[i].device).title_ru,
                    titleEN: JSON?.parse(response.data[i].device).title_eng
                }
            }

        })
        // //
        //
        HTTP.get(`/document/get_document_number/4`).then(response => {
            // console.log(response.data);
            for (let i = 0; i < response.data.length; i++) {
                this.documentation[i] = {
                    id: response.data[i].id,
                    titleRU: JSON?.parse(response.data[i].device).title_ru,
                    titleEN: JSON?.parse(response.data[i].device).title_eng,
                    type: 'documentation'
                }
            }
        })

        //
        HTTP.get(`/safety/get_safety_number/4`).then(response => {
            for (let i = 0; i < response.data.length; i++) {
                // console.log(response.data[i].id);
                this.industrialSafety[i] = {
                    id: response.data[i].id,
                    titleRU: JSON.parse(response.data[i].device).title_ru,
                    titleEN: JSON.parse(response.data[i].device).title_eng,
                    type: 'safety'

                }
            }
        })
    },
    mounted() {
        // store.commit('checkAdminStatus')
        const callback = (entries, observer) => {
            if (entries[0].isIntersecting) {
                this.watchComponent = true
            }
        }
        const observe = new IntersectionObserver(callback)
        observe.observe(this.$refs.team)
    },
    watch: {
        watchComponent(newQuestion, oldQuestion) {
            if (newQuestion) {
                this.getCommands()
            }
        }
    },
    setup() {

        useHead({
            title: `О нас - ООО «Трансмаш»`,
            meta: [
                {
                    name: `description`,
                    content: `Мы - производитель горно-шахтного оборудования, который предлагает своим клиентам широкий выбор высококачественных товаров.`
                },
                {
                    name: `keywords`,
                    content: `описание, о нас, история компании, разработка, высокотехнологичная продукция, услуги, сервис, трансмаш, оборудование для шахт, горно-шахтное оборудование`
                }
            ]

        })
    }
}

</script>

<style scoped>
.aboutPageContainer {
    position: relative;
    overflow-y: scroll;
    height: 100vh;
    overflow-x: hidden;

}

@media (max-width: 900px) {
    .Circle{
        display: none;
    }
}
</style>
