<template>
    <button  @click="toggleTheme(!isDarkMode)" class="header-light-switcher"  v-bind:style="{filter: theme == 'light' ? 'invert(0)' : 'invert(1)'}">
        <svg v-if="isDarkMode" width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4983" cy="12.4998" r="6.47321" stroke="white" stroke-width="1.33929"/>
            <path d="M12.5 3.57164V0.893066" stroke="white" stroke-width="1.33929" stroke-linecap="round"/>
            <path d="M12.5 24.1073V21.4287" stroke="white" stroke-width="1.33929" stroke-linecap="round"/>
            <path d="M21.4269 12.5L24.1055 12.5" stroke="white" stroke-width="1.33929" stroke-linecap="round"/>
            <path d="M0.891741 12.5L3.57031 12.5" stroke="white" stroke-width="1.33929" stroke-linecap="round"/>
            <path d="M18.813 18.8135L20.707 20.7075" stroke="white" stroke-width="1.33929" stroke-linecap="round"/>
            <path d="M4.29346 4.292L6.1875 6.18604" stroke="white" stroke-width="1.33929" stroke-linecap="round"/>
            <path d="M6.18701 18.8125L4.29297 20.7065" stroke="white" stroke-width="1.33929" stroke-linecap="round"/>
            <path d="M20.7065 4.29298L18.8125 6.18701" stroke="white" stroke-width="1.33929" stroke-linecap="round"/>
        </svg>
        <svg v-else width="20" height="20" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6968 20.7605C20.6175 20.6076 21.3632 21.472 20.7061 22.1098C19.5609 23.2216 18.1422 23.9495 16.8463 24.1086C14.8629 24.3522 12.3836 24.2275 10.5734 23.412C8.76324 22.5965 6.96754 21.1019 5.89521 19.4817C4.82288 17.8614 4.14014 16.0035 4.14014 14.0838C4.14014 12.1642 4.82288 10.434 5.89521 8.81371C6.96754 7.19346 8.76324 5.69892 10.5734 4.88341C12.3836 4.06789 14.8629 3.81545 16.8463 4.05903C18.239 4.23007 19.7737 4.84414 20.9582 5.97222C21.5761 6.56077 20.9383 7.37142 20.0823 7.21952C17.9024 6.83269 16.4816 6.94026 15.2527 7.69076C13.6591 8.66403 11.7435 10.9319 11.7435 14.0838C11.7435 17.2358 13.6591 18.9202 15.2527 20.0431C16.3563 20.8208 17.8946 21.0599 19.6968 20.7605Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
</template>

<script>
    import store from "@/store"

    export default {
        data(){
            return {
                isDarkMode: false,
            }
        },

        methods: {
            toggleTheme(theme){
                // console.log(theme);
                this.isDarkMode = theme;
                // console.log('document.documentElement.className = ', this.isDarkMode ? "dark" : "light");
                document.documentElement.className = this.isDarkMode ? "dark" : "light";
                localStorage.setItem('theme', theme);
                this.isDarkMode == true ? store.commit('changeThemeDark') : store.commit('changeThemeLight')
            },
        },

        mounted(){
            this.toggleTheme(JSON.parse(localStorage.getItem('theme')));
            // console.log(JSON.parse(localStorage.getItem('theme')))
        },

        props: {
            theme: {
                type: String,
                default: 'light'
            }
        }
    }
</script>

<style scoped>
    .header-dark-switcher {
        width: 20px;
        height: 20px;
        background-color: transparent;
        background-size: 16px;

        background-repeat: no-repeat;
        background-position: center;
        border: none; 
        outline: none;
        cursor: pointer;
    }

    .header-light-switcher {
        width: 20px;
        height: 20px;
        border: none; 
        outline: none;
        background-size: 20px;
        background-color: transparent;

        background-repeat: no-repeat;
        background-position: center;


        cursor: pointer;
    }
</style>