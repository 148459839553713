<script setup>
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n({useScope: 'global'});
</script>

<template>
  <div class="video-container" @mousemove="moveBackground($event)">
    <div class="decorate-circles"></div>

    <div class="text-container">
      <div class="sub-text-container">
        <h1>{{$t('Header.vacancy')}}</h1>
        <p>
          Подберите подходящую работу для себя <br/>
          и станьте частью команды компании.
        </p>
        <button @click="(e) => {scrollTo('vacancySelection'); e.stopPropagation()}" class="readMore">
          <img src="../assets/icons/ArrowButton.png" alt="">
          {{ $t('Buttons.readMore') }}
          <img src="../assets/icons/ArrowButton.png" alt="">
        </button>
      </div>

    </div>
    <div class="main-image-container">
      <div
          class="decorate-gradient"
          v-bind:style="{ marginTop: backgroundX*5 + 'px',
                            marginLeft: backgroundY*5 + 'px' }">
      </div>
      <div class="over-gradient">
      </div>
      <img class="background-image" src="@/assets/backgrounds/vacancy.webp">
    </div>


    <!-- <iframe width="1280" height="720" src="" title="Трансмаш набирает специалистов" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
  </div>
</template>

<script>
  export default {
    name: "VideoHeader",
    data(){
      return {
        backgroundX: 0,
        backgroundY: 0,

      }
    },

    props: {
      url: {
        type: String,
        default: '',
        mute: false,
      }
    },

    methods: {
      scrollTo(elementID){
        let DOMelement = document.getElementById(elementID)
        DOMelement.scrollIntoView({block: "start", behavior: "smooth"});
        // this.picked = elementID
      },
      moveBackground(e){

        // this.backgroundX = (window.innerWidth/2 - e.clientX)/50;
        // this.backgroundY = (window.innerHeight/2 - e.clientY)/50;

        if(typeof this.movewait != 'undefined'){
          clearTimeout(this.movewait);
        }

        this.movewait = setTimeout(function(){
          // this.backgroundX = (window.innerWidth/2 - e.clientX)/50;
          // this.backgroundY = (window.innerHeight/2 - e.clientY)/50;
          this.changeMousePosition((window.innerWidth/2 - e.clientX)/30, (window.innerHeight/2 - e.clientY)/30)
          // console.log();
        }.bind(this),50);

        // console.log(e);
      },

      changeMousePosition(x, y){
        this.backgroundX = x;
        this.backgroundY = y;
      },
    },


    mounted(){

      // window.addEventListener('mousemove', () => {
      //   document.getElementsByClassName('video_player')[0].click()
      // })

      // setTimeout(() => {
      //   alert(1);
      //   document.getElementsByClassName('video_player')[0].click();
      // }, 3000)
    }
  }
</script>

<style scoped>
  .video-container  {
    width: 100vw;
    height: 80vh;
    background-color: black;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  .main-image-container {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .background-image {
    width: 80%;
    height: 100%;
    object-fit: cover;
    top: 0;
    right: 0;
    animation: image-smoother 1s ease-in-out;
    background-image: url(@/assets/backgrounds/background1.webp);

    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;

    /*transform: scale(1.1);*/
    pointer-events: none;

    position: absolute;

    transition: 0.5s;
    background-attachment: fixed;

  }

  @keyframes top-down-opacity {
    0% {
      transform: translateX(-300px) rotate(-90deg);
      opacity: 0;
    }

    100% {
      transform: translateX(0px) rotate(-90deg);
      opacity: 1;
    }
  }
  .decorate-circles {
    animation: top-down-opacity .8s ease-in-out forwards;

    opacity: 0;
    left: 0;

    width: 500px;
    height: 500px;
    background-repeat: no-repeat;

    position: absolute;
    bottom: 0;

    background-image: url(@/assets/backgrounds/decorate_circles.svg);
    transform: rotate(-90deg);

    z-index: 3;
  }
  .decorate-gradient {
    width: 500px;
    height: 500px;

    position: absolute;
    z-index: 3;

    /* border: solid 1px red; */
    top: 40%;
    left: -10%;

    /* background: radial-gradient(circle, rgba(29,113,184,0.4) 0%, rgba(0,212,255,0) 80%); */
    background-color: rgba(29,113,184,0.4);
    filter: blur(100px);

    border-radius: 100%;

    background-position: center;
    background-repeat: no-repeat;

    transition: 1s;
  }
  .video_player {
    height: 100%;
  } 
  
  iframe {
    min-width: calc(1920px * 0.725);
  }

  @media (max-width: 900px) {
    .main-image-container{

      width: 100%;
      position: absolute;

    }
    .background-image{
    display: flex;
    background-image: '@assets/backgrounds/vacancy.webp';
    /* background-color: aquamarine; */
    height: 100%;
    width: 100%;
    min-width: 450px;
    background-repeat: no-repeat;
    background-attachment: fixed;
        background-size: cover;
        box-sizing: border-box;
        background-position: center;


  }

    .text-container {
      align-items: center;
    }

    .video-container {
      justify-content: center !important;
      height: 100vh;
      
    }

    .sub-text-container {
      min-width: 100vw !important;
      justify-content: center !important;
      align-items: center !important;

    }

    h1 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  .over-gradient {
    width: 32%;
    height: 100%;
    background: black;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
  }

  .over-gradient::after {
    content: '';
    width: 100%;
    height: 100%;

    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0, 0, 0, 0) 100%);

    position: absolute;
    transform: translateX(99%);

  }

  .text-container {
    width: 100vw;
    position: absolute;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .sub-text-container {
    width: 95%;
    height: 75%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    
    gap: 64px;
  }

  @keyframes opacity-up {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  h1 {
    text-transform: uppercase;
    opacity:0;
    width: 85%;
    font-size: 98px;
    color: white;
    /*line-height: .88;*/

    overflow: hidden;
    z-index: 20;

    animation: opacity-up 0.6s ease-in-out forwards;
    animation-delay: 0.6s;
  }

  p {
    text-transform: uppercase;
    opacity:0;
    width: 85%;
    font-size: 22px;
    font-weight: 100;
    color: white;
    /*line-height: .88;*/
    animation: opacity-up 0.6s ease-in-out forwards;
    animation-delay: 1.2s;
    overflow: hidden;
    z-index: 20;
  }

  .readMore {
    /*position: absolute;*/
    /*bottom: 0;*/
    color: white;
    font-size: 20px;
    z-index: 20;
    /*left: 100px;*/
    cursor: pointer;
    height: 38px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
    width: 250px;
    background: none;
    border: 1px solid white;
    border-radius: 15px; 
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent; 
  }
@media (max-width: 700px) {
  h1 {
    font-size: 60px;
  }
}
@media (max-width: 420px) {
  h1 {
    font-size: 50px;
  }
}
</style>
