<template>
  <ul class="container-body">
    <SkeletonFiltCard v-for="card in 4" v-if="cards.length < 1" />

    <FiltCard
      v-else
      v-for="(card, index) in cards"
      :cardText="JSON.parse(card.title).title_ru"
      :cardName="JSON.parse(card.title_transcription).title_ru"
      :cardImage="card.image"
      :index="index"
      :id="cards[index].id"
      :user="user"
      :category_type="category_type"
    />

    <div>
      <router-link to="/createProduct" class="container" v-if="user == 'admin'">
        <img src="@/assets/icons/add_product.svg" alt="" class="add-icon" />
        <img />
      </router-link>
    </div>
  </ul>
</template>

<script>
import { HTTP } from "@/plugins/http-common";
import CustomCard from "./ui/CustomCard.vue";
import SkeletonFiltCard from "./Sceletons/SkeletonFiltCard.vue";
import SceletonCard from "./ui/SceletonCard.vue";

export default {
  name: "AllCards",
  components: {
    CustomCard,
    SkeletonFiltCard,
    SceletonCard,
  },
  data() {
    return {
      user: "user",
    };
  },
  props: {
    title: {
      type: String,
      default: "Default Title",
    },

    description: {
      type: String,
      default: "Default description",
    },

    cards: {
      default: [],
    },
    category_type: {
      type: String,
      default: ''
    }
  },

  mounted() {
    if (localStorage.user) this.user = localStorage.user;
    console.log(this.cards);
  },

  created() {
    console.log(this.cards);
    
  }
};
</script>

<style scoped>
h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 48px;
}

.container-body {
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.container {
  width: 280px;
  height: 346px;

  margin: 20px;

  background: var(--card-bg);

  border-radius: 12px;

  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(
      to bottom,
      rgba(20, 20, 20, 0.1),
      #ccc,
      rgba(20, 20, 20, 0.1)
    )
    1 100%;

  box-shadow: var(--card-box-shadow);

  padding-top: 0px;
  padding-bottom: 24px;
}

.image-container {
  width: 100%;
  height: 280px;

  overflow: hidden;

  position: relative;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.blurImage {
  width: 100%;
  height: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  /* opacity: 0.2; */
  position: absolute;
  filter: blur(20px);
  opacity: 0.6;
}

.normalImage {
  width: calc(100% - 15px);
  height: calc(100% - 15px);

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;

  z-index: 2;
}

.text-container {
  bottom: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  color: var(--text);

  font-size: 16px;
}

.container {
  width: 280px;
  height: 426px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  justify-content: center;
  background: #e9edf0;

  border-radius: 22px;

  padding-top: 0px;
  padding-bottom: 24px;
}

.add-icon {
  cursor: pointer;
}

@media (max-width: 1287px) {
  .container-body {
    max-width: 966px;
  }
}

@media (max-width: 965px) {
  .container-body {
    max-width: 644px;
  }
}

@media (max-width: 643px) {
  .container-body {
    max-width: 322px;
  }
}
</style>
