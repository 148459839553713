<template>
  <div class="container">
    <div class="sub-container">
      <div class="container-vacancy-name-date">
        <h1 class="vacancy-name">
          {{data.title}}
        </h1>
        <p class="date">
          {{ data.created_at?.replace( /\-/g, '/') }}
        </p>
      </div>
      <p class="payday">
        от {{data.salary_min}} до {{data.salary_max}} руб. {{ data.salary_type?.toLowerCase() }}
      </p>
      <div class="container-icons">
        <div class="city">
          <div class="icons" :style="{backgroundImage: `url(${require('@/assets/icons/location.png')})`}"></div>
          <p>
            {{data.city}}
          </p>
        </div>

        <div class="time">
          <div class="icons" :style="{backgroundImage: `url(${require('@/assets/icons/clock.png')})`}"></div>
          <p>
            {{data.work_time}}
          </p>
        </div>

        <div class="experience">
          <div class="icons" :style="{backgroundImage: `url(${require('@/assets/icons/person.png')})`}"></div>
          <p>
            {{data.experience}}
          </p>
        </div>
        <div class="remote-work" v-show="data.remote_work">
          <div class="icons" :style="{backgroundImage: `url(${require('@/assets/icons/person.png')})`}"></div>
          <p>
            Удалённая работа
          </p>
        </div>
      </div>
      <button class="button" @click="clicked = !clicked" >
        {{ $t('Buttons.buy') }}
      </button>
    </div>
  </div>

  <Modal :clicked="clicked"
         :getClicked="getClickedParent"
         :title="data.title"
         :vacancy="true"
  />
</template>

<script>
import CustomButton from "@/components/ui/CustomButton.vue";
import Modal from "@/components/ui/Modal.vue";

export default {
  name: 'ParserVacancyFull',
  components: {Modal, CustomButton},
  props: {
    data: {}
  },
  data() {
    return {
      clicked: false
    }
  },
  methods: {
    getClickedParent(clicked) {
      this.clicked = clicked
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--text);
  height: 100%;
}
.sub-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  max-width: 940px;
  max-height: 350px;
  background: var(--card-bg);
  box-shadow: var(--card-box-shadow);
  border-radius: 15px;
  padding: 30px;
}
.vacancy-name {
  font-size: 35px;
  font-weight: 600;
}
.date {
  font-weight: 400;
  font-size: 20px;
  color: #808080;
}
.container-vacancy-name-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payday {
  font-size: 25px;
  font-weight: 400;
}
.container-icons {
  display: flex;
  column-gap: 25px;
  font-size: 20px;
  font-weight: 400;
}
.city, .time, .experience, .remote-work {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.icons {
  width: 21px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 21px;
}
.button {
  width: 80%;
  max-width: 350px;
  height: 48px;
  border-radius: 15px;
  font-size: 20px;
  margin: 0 auto;
  color: var(--blue);
  border: solid 1px var(--blue);

  text-transform: uppercase;

  text-decoration: none;
  display: flex;
  column-gap: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Geometria_regular;

  background: rgb(0,0,0,0);

  cursor: pointer;

  transition: 0.3s;
}

.button:hover {
  letter-spacing: 2px;
}

@media (max-width: 1020px) {
  .sub-container {
    width: 85%;
  }
}
@media (max-width: 800px) {
  .container-vacancy-name-date {
    flex-direction: column;
  }
  .container-icons {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
  .payday {
    text-align: center;
  }
}
@media (max-width: 550px) {
  .vacancy-name {
    font-size: 25px;
  }
  .sub-container {
    width: 80%;
  }
  .payday {
    font-size: 20px;
  }

}
@media (max-width: 390px) {
  .vacancy-name {
    font-size: 20px;
  }
  .payday {
    font-size: 16px;
  }

}
</style>