<template>
  <div class="container">
    <h2 class="title">{{ title }}</h2>
    <p class="description">{{ description }}</p>
    <div class="sub-container">
      <button class="arrow-left" :onClick="prevCard" v-if="cards.length > 1" >
        <div></div>
      </button>
      <ul v-if="contacts === false && cards.length > 0" class="cards-container">
        <CustomCard
            v-for="(card, index) in cards"
            :cardText="card.title !== undefined ? $store.state.lang == 'ru-RU' ? JSON.parse(card.title).title_ru : JSON.parse(card.title).title_eng : '' "
            :cardImage="card.image !== undefined ? card.image : '' "
            :cardName="card.title_transcription !== undefined ? JSON.parse(card.title_transcription).title_ru : ''"
            :index="index"
            :id="cards[index].id"
            :isClicked="isClicked"
            :category_type="category_type"
            :viewIndex="cards.length < 2 ? viewIndex - 1 : viewIndex"
            v-touch:swipe.left="nextCard"
            v-touch:swipe.right="prevCard"
        />
      </ul>

      <ul v-else-if="contacts === true && cards.length > 0" class="cards-container">
        <ContactCard 
              v-for="(card, index) in cards"
              :cardText="$store.state.lang == 'ru-RU' ? card?.textRU : card?.textEN"
              :cardImage="card?.img"
              :index="index"
              :viewIndex="cards.length < 2 ? viewIndex - 1 : viewIndex"
              :cardDescription="$store.state.lang == 'ru-RU' ? card?.descriptionRU : card?.descriptionEN"
              :phone="card?.phone"
              :email="card?.email"
              v-touch:swipe.left="nextCard"
              v-touch:swipe.right="prevCard"             
        />
      </ul>

      <div v-else class="cards-container">
        <SceletonCard
            v-for="(card, index) in number"
            :index="index"
            :viewIndex="viewIndex"
            v-touch:swipe.left="nextCard"
            v-touch:swipe.right="prevCard"             
        />
      </div>

      <button class="arrow-right" :onClick="nextCard" v-if="cards.length > 1">
        <div></div>
      </button>

      <CustomButton class="buttom"
          v-show="contacts === true"
          :style="{position: 'absolute', bottom: '40px'}"
          :link="cards.length < 2 ? link + cards[0]?.id : cards[viewIndex]?.id === undefined ? link : link + cards[viewIndex]?.id"
          :buttonText="$t('Buttons.more')"
      />
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return {
      viewIndex: 1,
      pickedIndex: 0,
      number: 5
    }
  },

  props: {
    title: {
      type: String,
      default: "Default Title"
    },

    description: {
      type: String,
      default: "Default description"
    },

    cards: {
      default: [],
    },

    category_type: {
      type: String
    },


    contacts: {
      type: Boolean,
      default: false,
    },
    link: {

      default: "404"
    },

    isClicked: {
        type: Boolean,
        default: false
    },

    isButton: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    nextCard() {
      this.$emit('next-card');
      this.viewIndex += 1;
      if (this.cards.length !== 0 ? this.viewIndex >= this.cards.length : this.viewIndex >= this.number) {
        this.viewIndex = 0;
      }
    },

    prevCard() {
      this.$emit('prev-card');
      this.viewIndex -= 1;
      if (this.viewIndex < 0) {
        this.viewIndex = this.cards.length > 0 ? this.cards.length - 1 : this.number - 1;
      }

    },


  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 44px;
}

button {
  border: none;
  background: none;
  outline: none;
}

.sub-container {
  width:100% ;
  max-width: 1300px;
  height: 60%;
  
  display: flex;
  justify-content: center;
  /* align-items: center; */

}



.description {
  padding: 20px;
  padding-bottom: 48px;
}

.arrow-left, .arrow-right {
  
  /* border: solid 1px red; */
  width: 100%;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.arrow-left {
  left: 0px;
  user-select: none;
  justify-content: left;
  z-index: 3;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
-webkit-tap-highlight-color: transparent; 
}
.arrow-right {
  right: 0px;
  user-select: none;
  justify-content: right;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
-webkit-tap-highlight-color: transparent; 
}

.arrow-left div, .arrow-right div {
  width: 70px;
  height: 100%;

  background: none;
  outline: none;
  border: none;

  cursor: pointer;

  background-image: url('../assets/icons/arrow.png');
  background-size: 50%;

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(var(--invert));

}

.arrow-left div {
  transform: rotate(90deg);
}

.arrow-right div {
  transform: rotate(-90deg);
}

.arrow-left div:focus {
  transform: rotate(90deg) scale(1);
}

.arrow-left div:active {
  transform: rotate(90deg) scale(0.7);
}

.arrow-right div:focus {
  transform: rotate(-90deg) scale(1);
}

.arrow-right div:active {
  transform: rotate(-90deg) scale(0.7);
}


h2 {
  font-size: 48px;
  color: var(--text);
  text-align: center;
  font-family: geometria_extralight;
  
  text-transform: uppercase;
}

h3 {
  font-size: 26px;
  font-weight: 100;
  color: var(--text);
  text-align: center;
  
}
p {
  font-size: 26px;
  font-weight: 100;
  color: var(--text);
  text-align: center;

}

@media (max-width: 1300px){
  .sub-container{
    width: 95%;
  }
}



@media (max-width: 1150px){
  .sub-container{
    width: 70%;
  }
}

@media (max-width: 650px){
  .sub-container{
    width: 90%;
  }
}

@media (max-width: 550px){
  .title{
    text-align: center;
    width: 90%;
    
  }

  .container{
    height: 700px;
    
  }

  h2{
    font-size: 28px;
    text-align: center;
  }

  .buttom{
    margin-bottom: 20px;
  }
}

@media (max-width: 550px){
  .sub-container{
    width: 97%;
  }
}


@media (max-width: 420px){
  
  .container{
    height: 600px;
    
  }

  .buttom{
    margin-bottom: 0px;
  }
  .sub-container{
    width: 105%;
  }

  .arrow-left div, .arrow-right div {
    width: 60px;
  }
}


</style>
