<script setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n({useScope: 'global'});
</script>

<template>

  <div class="container" @mousemove="moveBackground($event)">
    <div class="decorate-circles"></div>

    <div class="subContainer" v-if="Object.keys(newsArray[backgroundNumber]).length !== 0">

      <div class="imageContainer">
        <div
            class="decorate-gradient"
            :style="{ marginTop: backgroundX*5 + 'px',
                              marginLeft: backgroundY*5 + 'px' }">
        </div>
        <div class="black-gradient"></div>
        <div :style="{backgroundImage: `url(${require('../assets/backgrounds/' +   backgrounds[backgroundNumber])})`}"
            class="slider-image">
        </div>
      </div>


      <div class="text-container">

        <div class="h1-container">

          <h1>
            Новости
          </h1>

          <p>
            Читайте последние новости <br>
            Будьте в курсе всех обновлений и событий нашей компании.
          </p>


        </div>
      </div>


    </div>

    <SceletonNewsSlider
      v-else
    />


  </div>

</template>

<script>
import {HTTP} from "@/plugins/http-common";
import SceletonNewsSlider from "@/components/Sceletons/SceletonNewsSlider.vue";


export default {
  name:'NewsSlider',
  components: {
        SceletonNewsSlider,
    },
  data(){
    return {
      movewait: undefined,
      backgroundX: 0,
      backgroundY: 0,
      autoSkip: false,
      backgroundNumber: 0,
      newsArray: [{}, {}, {}, {}],

      backgrounds: ['microBackground1.webp',
        'microBackground2.webp',
        'microBackground3.webp',
        'microBackground4.webp'],

    }
  },

  methods: {

    scrollTo(elementID){
      let DOMelement = document.getElementById(elementID)
      DOMelement.scrollIntoView({block: "start", behavior: "smooth"});
      // this.picked = elementID
    },

    moveBackground(e){

      // this.backgroundX = (window.innerWidth/2 - e.clientX)/50;
      // this.backgroundY = (window.innerHeight/2 - e.clientY)/50;

      if(typeof this.movewait != 'undefined'){
        clearTimeout(this.movewait);
      }

      this.movewait = setTimeout(function(){
        // this.backgroundX = (window.innerWidth/2 - e.clientX)/50;
        // this.backgroundY = (window.innerHeight/2 - e.clientY)/50;
        this.changeMousePosition((window.innerWidth/2 - e.clientX)/30, (window.innerHeight/2 - e.clientY)/30)
      }.bind(this),50);

    },

    changeMousePosition(x, y){
      this.backgroundX = x;
      this.backgroundY = y;
    },

    newsReqeust(number) {
      this.backgroundNumber = number
      HTTP.get(`/news/get_all_news/${this.backgroundNumber +1}/1`).then(response => {
          this.newsArray[this.backgroundNumber] = response.data.data[0]
          
      }).catch(error => {
          console.error('Error fetching news:', error);
      });
    }
  },

  mounted() {
    setInterval(() => {
      if(this.autoSkip){
        this.backgroundNumber++
        if(this.backgroundNumber >= this.backgrounds.length){
          this.backgroundNumber = 0;
        }
        if (Object.keys(this.newsArray[this.backgroundNumber]).length === 0) {
          this.newsReqeust(this.backgroundNumber)
        }
        
      }
    }, 6000)
  },

  created() {
      this.newsReqeust(0)
    },

  destroyed() {

  }

}
</script>

<style scoped>
.container  {
  width: 100%;
  height: 70vh;
  background-color: #040405;
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.subContainer {
  display: flex;
  flex-direction: row-reverse;
  max-width: 1300px;

  height: 100%;
}

.imageContainer {
  width: 50%;
  min-width: 50%;
  height: 100%;
}

.black-gradient {
  width: 100vw;
  height: 100%;

  position: absolute;

  background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(2,2,2,1) 100%);
  background-position-x: 0px;

  pointer-events: none;

  z-index: 2;
}

@keyframes image-smoother {
  0% {
    transform: translateX(50px) scale(1.1);
    opacity: 0;
  }

  100% {
    transform: translateX(0px) scale(1.1);
    opacity: 1;
  }
}

.slider-image {
  width: 100vw;
  height: 100vh;
  animation: image-smoother 1s ease-in-out;
  /*background-image: url(@/assets/backgrounds/background1.png);*/

  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;

  /*transform: scale(1.1);*/
  pointer-events: none;

  position: absolute;

  transition: 0.5s;
  background-attachment: fixed;

}


.decorate-gradient {
  width: 500px;
  height: 500px;

  position: absolute;
  z-index: 3;

  top: 40%;
  left: -10%;
  
  background-color: rgba(29,113,184,0.4);
  filter: blur(100px);

  border-radius: 100%;

  background-position: center;
  background-repeat: no-repeat;

  transition: 1s;
}

@keyframes left-opacity {
  0% {
    transform: translateX(-400px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}



.h1-container {
  width: 100%;
 
  /*margin-top: 3%;*/

  /*height: 400px;*/
  /*padding-left: 100px;*/
  padding-top: 10%;
  padding-left: 2%;
  display: flex;
  box-sizing: border-box;
  row-gap: 25px;
  /*position: relative;*/
  flex-direction: column;
  overflow: visible;
}

/* .h1-container > span {
  min-width: 630px;
} */
.text-container {
  /* position: absolute; */
  width: 50%;
  height: 100%;
  min-width: clamp(500px, 40vw, 1800px);
  display: flex;
  align-items: center;
  /* left: 10vw; */

  /*line-height: 1.5;*/

  overflow: hidden;

  /*pointer-events: none;*/

  z-index: 3;

}

h1 {
  

  font-size: 48px;
  text-transform: uppercase;
  color: white;
  /*line-height: .88;*/
  /*height: 30%;*/

  padding-top: 5px;

  
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  
}

p {
  /*margin-top: 100px;*/
  font-size: 20px;
  
  font-weight: 300;
  color: rgb(255, 255, 255, 0.8);
  

}

.containerButtonAndDate {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.date {
  /*position: absolute;*/
  /*right: 40px;*/
  /*bottom: 0;*/
  font-size: 20px;
  color: #808080;
}

.readMore {
  /*position: absolute;*/
  /*bottom: 0;*/
  color: white;
  font-size: 20px;
  z-index: 20;
  /*left: 100px;*/
  cursor: pointer;
  height: 38px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  width: 250px;
  background: none;
  border: 1px solid white;
  border-radius: 15px; 
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent; 
}

.readMore:hover {
  letter-spacing: 2px;
  transition: 0.3s;
}

.dots {
  /*position: absolute;*/
  /*bottom: 32px;*/
  overflow: hidden;
  width: 62px;
  height: 14px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  /*left: calc(50% - 31px);*/
  z-index: 3;
  margin: 0 auto;
  padding: 30px 0;
  /* pointer-events: none; */
}

.picked-dot{
  width: 14px !important;
  height: 14px !important;
}
.dot {
  width: 8px;
  height: 8px;

  border-radius: 100%;

  background-color: white;
  cursor: pointer;

  transition: 0.5s;
}


@keyframes top-down-opacity {
  0% {
    transform: translateX(-300px) rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0px) rotate(-90deg);
    opacity: 1;
  }
}

.decorate-circles {
  animation: top-down-opacity .8s ease-in-out forwards;

  opacity: 0;
  left: 0;

  width: 500px;
  height: 500px;
  background-repeat: no-repeat;

  position: absolute;
  bottom: 0;

  background-image: url(@/assets/backgrounds/decorate_circles.svg);
  transform: rotate(-90deg);

  z-index: 3;
}



@media (max-width: 1300px){
  
  
  .h1-container {
    display: flex;
    width: 100%;
    /* padding-right: 400px; */
    /*justify-content: center;*/
    flex-direction: column;
    
    margin-left: 50px;
    margin-top: 40px;
  }

  .slider-image {
    background-position-x: 50% !important;
  }



  

  

}

@media (max-width: 1050px){
  .containerButtonAndDate{
    flex-direction: column;
    
  }
  
  .date{
    margin-top: 20px;
  }
}

@media (max-width: 900px){
  .imageContainer{
    /* width: 100%; */
    position: absolute;
  }

  .text-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
     
  }


  .h1-container{
    width: 70%;
    margin-left: 0px;
  }

  .h1-container h2{
    text-align: center;
    font-size: 28px;
  }

  .h1-container p{
    text-align: center;
    font-size: 18px;
  }
  
  .decorate-gradient {
    display: none;
  }

  .black-gradient{
    display: none;
  }

  .containerButtonAndDate{
    flex-direction: column;
    align-items: center;
  }

  h1{
    text-align: center
  }

}

@media (max-width: 550px){
  .h1-container{
    width: 80%;
    align-items: center;
  }

  p{
    width: 80%;
  }
}

@media (max-width: 400px){
  .h1-container h2{
    
    font-size: 24px;
  }

  .h1-container p{
    
    font-size: 18px;
  }

  .dots{
    height: 20px;
  }
}

 

</style>
