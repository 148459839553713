<template>
  <div
      v-motion
      :initial="{
            x: 100,
            opacity: 0,
        }"
      :enter="{
            x: 0,
            opacity: 1,
        }"
      class="container"
  >
    <div class="sub-container">
      <div
          v-for="slide in slides"
          class="title"
          :style="{
                color: dark && $store.state.theme == 'light' ? 'rgb(120, 120, 120)' : 'white',
          }"
          :class="{'pickedTitle': picked == slide.id}"
          @click="scrollTo(slide.id)"
      >
        {{slide.name}}
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return {

    }
  },

  methods: {
    scrollTo(elementID){
      let DOMelement = document.getElementById(elementID)
      DOMelement.scrollIntoView({block: "center", behavior: "smooth"});
      // this.picked = elementID
    }
  },

  props: {
    dark: {
      type: Boolean,
      default: false,
    },

    slides: {
      default: [],
    },

    picked: {
      default: 'main',
    }
  }
}
</script>

<style scoped>
.container {
  position: absolute;
  display: flex !important;
  right: 20px;
  width: 230px;
  height: 340px;

  top: calc(50% - 170px);
  overflow: hidden;

  z-index: 10;
}

button {
  border: none;
  background: none;
  outline: none;
}

@media (max-width: 1500px) {
  .container {
    display: none !important;
  }
}



.sub-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  /* color: white; */
  /* background-color: white; */
}

.title {
  font-size: 16px;
  font-weight: 100;
  text-transform: uppercase;

  text-align: right;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;

  border-right: solid 2px rgb(212, 212, 212);

  cursor: pointer;
  mix-blend-mode: difference;

  transition: 0.3s;
  overflow: hidden;
}

.pickedTitle {
  font-size: 26px;
  font-weight: 100;
  color: var(--blue)!important;

  text-transform: uppercase;

  text-align: right;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;

  border-right: solid 3px var(--blue);

  transition: 0.3s;

}

.pickedTitle::after {
  content: "";

  position: absolute;

  width: 120px;
  border-radius: 100%;
  height: 10px;
  margin-top: 12px;
  right: -60px;

  filter: blur(11px);

  background-color: var(--blue);
}


</style>
