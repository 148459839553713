<template>
  <div class="container">
    <div class="subContainer">

      <div class="containerHeader">
        <p class="p">
          {{ $t('ContactsPage.Office.header')  }}
        </p>
      </div>

      <ul class="containerBody">
        <a class="subContainerElement"
           href="https://yandex.ru/maps/-/CCU04UvtDC"
           target="_blank"
           v-show="!edit"
        >
          <img src="../assets/icons/location.png" alt="">
          {{locateRU}}
        </a>
        <div v-show="edit"
             class="subContainerElement"
        >
          <img src="../assets/icons/location.png" alt="">
          <input type="text"
                 v-if="$store.state.lang == 'ru-RU'"
                 v-model="locateRU"
                 class="custom-input"
          >
          <input type="text"
                 v-else
                 v-model="locateEN"
                 class="custom-input"
          >
        </div>

        <p class="subContainerElement" v-show="!edit">
          <img src="../assets/icons/clock.png" alt="">
          {{timeWorkRU}}
        </p>

        <div class="subContainerElement" v-show="edit">
          <img src="../assets/icons/clock.png" alt="">
          <input type="text"
                 v-if="$store.state.lang == 'ru-RU'"
                 v-model="timeWorkRU"
                 class="custom-input"
          >
          <input type="text"
                 v-else
                 v-model="timeWorkEN"
                 class="custom-input"
          >
        </div>

        <div class="containerColumns">
          <div class="subContainerColumns"
              v-for="(element, index) in $store.state.lang == 'ru-RU' ? arrayPhoneRU : arrayPhoneRU"
          >
              <div v-for="(number, indexe) in 2"
                  class="element"
              >
                <a :href="`tel:${element[index][indexe]?.phone?.split(' -')[0]}`"
                  class="linkTel"
                  v-show="!edit"
                >
                  <img src="../assets/icons/phone.png" alt="">
                  {{element[index][indexe]?.phone}}
                </a>

                <div v-show="edit"
                    class="linkTel"
                >
                  <img src="../assets/icons/phone.png" alt="">
                  <input type="text"
                        v-model="element[index][indexe].phone"
                        class="custom-input"
                  >
                </div>
              </div>

          </div>
        </div>


        <div class="subContainerColumns">
          <a href="mailto: info@transmlnk.ru"
             class="containerRow"
             v-show="!edit"
          >
            <img src="../assets/icons/message.png" alt="">
            {{arrayEmailRU[0]?.email}}
          </a>
          <div class="containerRow"
               v-show="edit"
          >
            <img src="../assets/icons/message.png" alt="">
            <input type="text"
                   v-model="email"
                   class="custom-input"
            >
          </div>
          <div class="subContainerMessage">
            <a class="containerRow"
               :style="{columnGap: `10px`}"
               :href="`https://t.me/` + arrayEmailRU[1]?.telegram.split('@').join('')"
               target="_blank"
               v-show="!edit"
            >
              <img src="../assets/icons/tg.png" alt="">
              <p>
                {{arrayEmailRU[1]?.telegram}}
              </p>
            </a>

            <div class="containerRow"
                 v-show="edit"
            >
              <img src="../assets/icons/tg.png" alt="">
              <input type="text"
                     v-model="telegram"
                     class="custom-input"
              >
            </div>

            <a class="containerRow"
               :style="{columnGap: `10px`}"
               :href="`https://vk.com/` + arrayEmailRU[2]?.vk"
               target="_blank"
               v-show="!edit"
            >
              <img src="../assets/icons/vk.png" alt="">
              <p>
                {{arrayEmailRU[2]?.vk}}
              </p>
            </a>

            <div class="containerRow"
                 v-show="edit"
            >
              <img src="../assets/icons/vk.png" alt="">
              <input type="text"
                     v-model="vk"
                     class="custom-input"
              >
            </div>

          </div>
        </div>
      </ul>

    </div>
    <div v-show="user === 'admin'"
         class="container-button-delete-edit"
    >
      <button v-show="!edit"
              @click="edit = true"
      >
        <img src="@/components/ui/assets/pencil.png" alt="">
      </button>

      <button v-show="edit"
              @click="handleSendOfficeRequisites"
      >
        <img src="@/assets/icons/save.svg" alt="">
      </button>
      <button v-show="edit"
              @click="handleCloseOfficeRequisites"
      >
        <img src="@/assets/icons/exit.svg" alt="">
      </button>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/plugins/http-common";
import store from "@/store";

export default {
  name: "ContactsOfficeCompany",
  data() {
    return {
      arrayPhoneRU: [],
      arrayPhoneEN: [],
      locateRU: "",
      locateEN: "",
      timeWorkRU: "",
      timeWorkEN: "",
      arrayEmailRU: [],
      user: "",

      email: "",
      telegram: "",
      vk: "",
      fals: this,

      edit: false,
    }
  },
  methods: {
    handleSendOfficeRequisites() {
      this.arrayEmailRU[0].email = this.email
      this.arrayEmailRU[1].telegram = this.telegram
      this.arrayEmailRU[2].vk = this.vk
      HTTP.put(`/office/edit_office`, {
        location: JSON.stringify({"body_ru": this.locateRU, "body_eng": this.locateEN}),
        time_work: JSON.stringify({"body_ru": this.timeWorkRU, "body_eng": this.timeWorkEN}),
        phones: JSON.stringify({"body_ru": this.arrayPhoneRU[0], "body_eng": this.arrayPhoneEN}),
        emails: JSON.stringify(this.arrayEmailRU)
      }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(response => {
        // console.log(response)
        this.edit = false
      }).catch(err => {
      })
    },
    handleCloseOfficeRequisites() {
      this.edit = false
      this.handleGetRequest()
    },
    handleGetRequest() {
      if (this.$store.state.lang === 'ru-RU') {
        HTTP.get(`/office/get_all_office`).then(response => {
          this.locateRU = JSON.parse(response.data.data[0].location).body_ru
          this.timeWorkRU = JSON.parse(response.data.data[0].time_work).body_ru
          this.arrayEmailRU = JSON.parse(response.data.data[0].email)
          for (let i = 0; i < 3; i++) {
            this.arrayPhoneRU[i] = JSON.parse(response.data.data[0].phones).body_ru
          }
          this.email = this.arrayEmailRU[0].email
          this.telegram = this.arrayEmailRU[1].telegram
          this.vk = this.arrayEmailRU[2].vk
        }).catch(err => {
        })
      } else {
        this.arrayEmailRU = []
      }
    }
  },

  mounted() {
    this.user = localStorage.user

    this.handleGetRequest()

  },
  created() {
  }
}
</script>

<style scoped>
    .container {
      padding-top: 35px;
      display: flex;
      padding-bottom: 50px;
      justify-content: center;
      align-items: center;
      color: var(--text);
      background: var(--default-bg);
      scroll-snap-align: center;
      column-gap: 10px;
    }
    .subContainerElement {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
    .subContainer {
      width: 900px;
      column-gap: 30px;
      display: flex;
      border-radius: 15px;
      box-shadow: var(--card-box-shadow);
      background: var(--card-bg);
      flex-direction: column;
      justify-content: center;
    }
    .containerColumns {
      display: flex;
      row-gap: 30px;
      flex-direction: column;
    }
    .linkTel {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 5px;
    }
    .linkTel img {
      width: 20px;
      height: 20px;
    }
    .element {
      max-width: 360px;
      width: 100%;
    }
    .container-button-delete-edit {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .container-button-delete-edit button {
      padding: 4px;
      border-radius: 5px;
      background-color: white;
      border: solid 1px #ccc;
      cursor: pointer;
    }

    .container-button-delete-edit button img{
      width: 20px;
      height: 20px;
    }
    .p {
      font-size: 48px;
      text-transform: uppercase;
      font-weight: 400;
      overflow: hidden;
    }
    .custom-input {
      outline: none;
      width: 100%;
      height: 40px;
      border-radius: 15px;
      border: 2px solid var(--border-color);
      padding-left: 10px;
    }
    .containerHeader {
      width: 100%;
      
      display: flex;
      padding-top: 50px;
      justify-content: center;
    }

    .containerBody {
      padding: 40px 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .subContainerColumns {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .containerRow {
      display: flex;
      column-gap: 20px;
      align-items: center;
    }
    .containerRowRight {
      width: 46.5%;
    }
    .subContainerMessage {
      display: flex;
      align-items: center;
      column-gap: 20px;
    }
    .subContainerMessage  img {
      width: 20px;
    }
    a {
        text-decoration: none;
        color: var(--text);
    }
    @media (max-width: 900px){
      .subContainer{
        max-width: 90%;
      }
    }

    @media (max-width: 660px){
      .subContainerColumns{
        
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      .containerHeader{
        text-align: center;
      }

     

      .containerRowRight{
        margin-left: 45px;
        width: 70%;
      }

      .subContainerMessage{
        flex-direction: column;
        align-items: start;
      }

      

      .subContainerMessage .containerRow p{
        margin-left: 15px;
      }

      .containerRow{
        margin-top: 5px;
      }
    }

</style>
