<template>


    <div class="container" >
        <h2>
          {{$t('NewsPreview.title')}}
        </h2>
        <div class="sub-container" >
            <router-link  :to="`/news/${specialNewsName.replaceAll(' ', '_')}?id=` + SpecialNewsID"
            class="special-news"
            >
                <div class="bluredImage" :style="{backgroundImage: `url(${specialImage})`}" ></div>
                <img class="normalImage" :src="specialImage" alt="image"/>

                <div class="special-news-info">
                    <p v-if="$store.state.lang == 'ru-RU'" class="special-new-title">{{ specialTitleRU }}</p>
                    <p v-else class="special-new-title">{{ specialTitleEN }}</p>
                    <router-link :to="`/news/${specialNewsName.replaceAll(' ', '_')}?id=` + SpecialNewsID" class="special-news-link">
                      {{$t('Buttons.readMore')}}
                    </router-link>
                    <p class="special-new-date" >
                      {{
                        specialCreated.toString().split(' ')[0]?.split('-').join('/') + ' '
                        + specialCreated.toString().split(' ')[1]?.split(":")[0] + ':'
                        + specialCreated.toString().split(' ')[1]?.split(":")[1]
                      }}
                    </p>
                </div>
            </router-link>
            <div class="other-news"
               
            >
                <MicroNews 
                    v-if="$store.state.lang == 'ru-RU'"
                    v-for="(title, index) in microNewsTitlesRU"
                    :FirstPar="title"
                    :id="newsIDRu[index]"
                    :Base64="images[index]"
                    :name="microNewsNames[index]"
                />  

                 

                <MicroNews 
                    v-else
                    v-for="(title, index) in microNewsTitlesEN"
                    :FirstPar="title"
                    :id="newsIDEn[index]"
                    :Base64="images[index]"
                    :name="microNewsNames[index]"
                /> 

            </div>
            
        </div>
    </div>
</template>

<script>
    import MicroNews from "@/components/MicroNews.vue"
    import SceletonNewsPreview from "@/components/Sceletons/SceletonNewsPreview.vue";
    import { HTTP } from '@/plugins/http-common'
// import store from "@/store"

    export default {
        components: {
            MicroNews,
            SceletonNewsPreview,
        },

        data(){
            return {
                specialTitleRU: '',
                specialTitleEN: '',
                specialImage: '',
                specialCreated: '',
                specialNewsName: '',

                SpecialNewsID: '',

                newsIDRu: [],
                newsIDEn: [],

                microNewsTitlesRU: [],
                microNewsTitlesEN: [],

                microNewsNames: [],

                images: [],
            }
        },

        created() {
          try {
              HTTP.get(`/news/get_all_news/0/4`).then(response => {
                console.log(response.data)
                this.specialTitleRU = JSON.parse(response.data.data[0].title).title_ru;
                this.specialTitleEN = JSON.parse(response.data.data[0].title).title_eng;
                this.specialNewsName =response.data.data[0].title_transcription;
                this.specialCreated = response.data.data[0].created_at
                this.SpecialNewsID = response.data.data[0].id;

                const formData = new FormData();
                formData.append('image', JSON.parse(response.data.data[0].image).image);

                HTTP.post('/get_image/download_image', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                  responseType: 'blob'
                })
                    .then((response) => {
                      this.specialImage = URL.createObjectURL(response.data)

                    })

                // for (let i in response.data.length) {
                //   console.log(1)
                //   this.microNewsTitlesRU[i - 1] = JSON.parse(response.data.data[i].title).title_ru;
                //   this.microNewsTitlesEN[i - 1] = JSON.parse(response.data.data[i].title).title_eng;
                //
                //   this.newsIDRu[i - 1] = response.data.data[i].id;
                //
                //   this.newsIDEn[i - 1] = response.data.data[i].id;
                //
                //   this.images[i - 1] = JSON.parse(response.data.data[i].image).image;
                // }
                for(let i = 0; i < 3; i++){
                  console.log(response.data)
                    this.microNewsTitlesRU[i - 1] = JSON.parse(response.data.data[i].title).title_ru;
                    this.microNewsTitlesEN[i - 1] = JSON.parse(response.data.data[i].title).title_eng;

                    this.microNewsNames[i - 1] = response.data.data[i].title_transcription;
                    this.newsIDRu[i - 1] = response.data.data[i].id;

                    this.newsIDEn[i - 1] = response.data.data[i].id;

                    const formData = new FormData();
                    formData.append('image', JSON.parse(response.data.data[i].image).image);

                    HTTP.post('/get_image/download_image', formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                      responseType: "blob"
                    })
                    .then((response) => {
                      this.images[i - 1] = URL.createObjectURL(response.data);

                    })
                  }

                // console.log(this.microNewsTitlesEN)
                // console.log(this.images)


              }).catch(err => {
                console.log('Ошибка в запросе', err);
              })

          } catch (e) {
            console.error(e)
          }
        },

    }
</script>

<style scoped>
    .container {
        width: 100%;
        flex-direction: column;
        gap: 10px;
        background-color: var(--about-bg);
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*padding-bottom: 50px;*/
        scroll-snap-align: center;
    }
    h2 {
      font-size: 52px;
      color: var(--text);

      font-family: geometria_extralight;

      text-transform: uppercase;
    }
    .special-news-link {
      position: absolute;
      font-size: 26px;
      bottom: 24px;
      color: white;

      font-family: geometria_bold;

    }
    .sub-container {

        overflow: hidden;

        width: 1300px;
        height: 100%;

        display: flex;
        flex-direction: column;

    }

    .special-news {
        width: 100%;
        height: 500px;
        
        box-sizing: border-box;
        border-radius: 12px;
        position: relative;

        background-position: center;
        
        
        background-repeat: no-repeat;
      

        overflow: hidden;
    }
    
    .bluredImage {
        width: 100%;
        height: 100%;

        position: absolute;
        background-position: center;
        background-size: cover;

        filter: blur(10px);
    }

    .normalImage {
        width: 100%;
        height: 100%;
        object-fit: contain;

        position: absolute;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .special-news:hover .special-news-info{
        height: 100%;
        cursor: pointer;
    }

    .special-news-info {
        width: 100%;
        height: 50%;
        position: absolute;
        
        background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%,rgba(255,255,255,0)  100%);
        bottom: 0;

        display: flex;
        padding-left: 24px;

        transition: 0.5s;
        
    }

    .special-new-title {
        font-size: 35px;
        color: white;
        font-family: geometria_bold;
        width: 70%;
        display: -webkit-box;
        -webkit-line-clamp: 3;

        -webkit-box-orient: vertical;

        overflow: hidden;
        position: absolute;
        bottom: 70px;
    }

    .special-new-date {
        font-size: 26px;
        color: white;

        position: absolute;
        right: 48px;
        bottom: 24px;
    }

    .other-news {
        width: 100%;
        
        box-sizing: border-box;
        padding-bottom: 50px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        padding-top: 24px;
        overflow: hidden;
    }

    @media (max-width: 1300px){
        .other-news{
            width: 95%;
           
        }

        .special-news{
            width: 95%;
        }

        .sub-container{
            justify-content: center;
            align-items: center;
        }

        .special-new-title {
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        
        }
    }

    @media (max-width: 800px){
        .other-news{
            flex-wrap: nowrap;
            flex-direction: column;
            
            
        }

        .special-news{
            height: 400px;
        }

        .special-new-title {
            font-size: 24px;
            padding-bottom: 24px;
            width: 85%;
        
        }

        .special-new-date {
            font-size: 18px;
        
        }

        .special-new-title {
        
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            
        }
    }
</style>