<template>
    <div>
        <ScrollMenu
            :dark="isMenuDark"
            :slides="[
                {id: 'main', name: $store.state.lang == 'en-EN' ? 'home' : 'главная'},
                {id: 'about', name: $store.state.lang == 'en-EN' ? 'about' : 'о нас'},
                {id: 'systems', name: $store.state.lang == 'en-EN' ? 'systems' : 'системы'},
                {id: 'devices', name: $store.state.lang == 'ren-EN' ? 'devices' : 'устройства'},
                {id: 'team', name: $store.state.lang == 'en-EN' ? 'team' : 'команда'},
                {id: 'news', name: $store.state.lang == 'en-EN' ? 'news' : 'новости'},
            ]"
            :picked="pickedSlide"
        />
        <div ref="sliderContainer" class="slides-container">
            <Header theme="light" />


            <div class='slide' ref="fullScreenSlider" id="main">
                <FullscreenSlider/>
            </div>


            <Circle :circleType="'right'"/>
            <div ref="about" id="about">
                <About/>
            </div>

<!--            <div ref="services" id="services">-->
<!--                <CardCarousel :style="{background: `none`}"-->
<!--                              :cards="$store.state.lang === 'ru-RU' ? provideServicesRU : provideServicesEN"-->
<!--                              :is-button="false"-->
<!--                              :is-clicked="true"-->
<!--                              :title="$store.state.lang === 'ru-RU' ? 'ПРЕДОСТАВЛЯЕМ УСЛУГИ' :  'WE PROVIDE SERVICES'"-->
<!--                              :description="$store.state.lang === 'ru-RU'-->
<!--                    ? 'В сфере  обслуживания горно-шахтного оборудования'-->
<!--                    : 'In the service sector of mining equipment' "-->
<!--                />-->
<!--            </div>-->

            <div uploadedNames style="width: 100%;"></div>
            <div id="systems" ref="systems">
                <ReadySystems/>
            </div>

            <Circle :circleType="'left'"/>

            <div ref="devices" id="devices">
                <CardCarousel :style="{background: `var(--about-bg)`}"
                              :cards="largeDevices"
                              :is-button="false"
                              :is-clicked="true"
                              :link="`/product/`"
                              :category_type="`Video_monitoring`"
                              :title="$store.state.lang === 'ru-RU' ? 'БОЛЬШОЙ СПЕКТР УСТРОЙСТВ' : 'WIDE RANGE OF DEVICES' "
                              :description="$store.state.lang === 'ru-RU'
                    ? 'Разработка и обслуживание автоматики для горно-шахтного оборудования.'
                    : 'Development and servicing of automation for mining equipment.'"

                />
            </div>
            <div ref="team" style="width: 100%;"></div>
            <div id="team">
                <CardCarousel
                        :title=" $store.state.lang == 'ru-RU' ? 'Наша команда' : 'Our team'"
                        :description="''"
                        link="team"
                        :cards="Team"
                        :contacts="true"
                />
            </div>

            <div ref="news" style="width: 100%;"></div>
            <div id="news">
                <SceletonNewsPreview v-if="Team == 0"/>
                <NewsPreview v-else/>
            </div>

            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"
import FullscreenSlider from "@/components/FullscreenSlider.vue"
import Footer from "@/components/Footer.vue"
import About from "@/components/About.vue"
import ReadySystems from "@/components/ReadySystems.vue"
import NewsPreview from "@/components/NewsPreview"
import ScrollMenu from "@/components/ScrollMenu.vue"
import CardCarousel from "@/components/CardCarousel.vue"
import SceletonNewsPreview from "@/components/Sceletons/SceletonNewsPreview.vue";

import Intersect from "vue-intersection";
import {useHead} from "@vueuse/head"
import {HTTP} from '@/plugins/http-common'
import store from "@/store";


export default {
    components: {
        Header,
        FullscreenSlider,
        Footer,
        About,
        ReadySystems,
        NewsPreview,
        ScrollMenu,
        CardCarousel,
        SceletonNewsPreview,
        Intersect

    },

    data() {
        return {
            isMenuDark: false,
            pickedSlide: 'main',
            Team: [],

            watchComponentTeam: false,
            watchComponentDevice: false,
            watchComponentService: false,

            provideServicesRU: [],
            provideServicesEN: [],

            largeDevices: []

        }
    },


    created() {





    },

    mounted() {
        // store.commit('checkAdminStatus')
        this.$refs.sliderContainer.addEventListener("scroll", e => {
            let br = this.$refs.fullScreenSlider.getBoundingClientRect();
            let br2 = this.$refs.about.getBoundingClientRect();
            // let br3 = this.$refs.services.getBoundingClientRect();
            let br4 = this.$refs.systems.getBoundingClientRect();
            let br5 = this.$refs.devices.getBoundingClientRect();
            let br6 = this.$refs.team.getBoundingClientRect();
            let br7 = this.$refs.news.getBoundingClientRect();

            if (br2.top < window.innerHeight / 2) {
                this.pickedSlide = 'about'
            }

            // if (br3.top < window.innerHeight / 2) {
            //     this.pickedSlide = 'services'
            // }

            if (br4.top < window.innerHeight / 2) {
                this.pickedSlide = 'systems'
            }

            if (br5.top < window.innerHeight / 2) {
                this.pickedSlide = 'devices'
            }

            if (br6.top < window.innerHeight / 2) {
                this.pickedSlide = 'team'
            }

            if (br7.top < window.innerHeight / 2) {
                this.pickedSlide = 'news'
            }

            if (br.bottom < window.innerHeight / 2) {
                // console.log(true)
                this.isMenuDark = true

            } else {
                this.isMenuDark = false
                this.pickedSlide = 'main'
            }
        })
        const callbackTeam = (entries, observer) => {
            if (entries[0].isIntersecting) {
                this.watchComponentTeam = true
            }
        }
        const observeTeam = new IntersectionObserver(callbackTeam)
        observeTeam.observe(this.$refs.team)

        const callbackService = (entries, observer) => {
            if (entries[0].isIntersecting) {
                this.watchComponentService = true
            }
        }

        // const observeService = new IntersectionObserver(callbackService)
        // observeService.observe(this.$refs.services)

        const callbackDevice = (entries, observer) => {
            if (entries[0].isIntersecting) {
                this.watchComponentDevice = true
            }
        }
        const observeDevice = new IntersectionObserver(callbackDevice)
        observeDevice.observe(this.$refs.devices)
    },
    methods: {
      getCommands() {
        HTTP.get('/command/get_all_command').then(response => {
          response.data.data.forEach((command, index) => {
            const imagePath = JSON.parse(command.image).image;

            const formData = new FormData();
            formData.append('image', imagePath);

            HTTP.post('/get_image/download_image', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              responseType: 'blob'
            }).then(responseImage => {
              const url = URL.createObjectURL(responseImage.data);

              this.Team[index] = {
                img: url,
                textRU: JSON.parse(command.body).body_ru[0][1],
                textEN: JSON.parse(command.body)?.body_eng[0][1],
                descriptionRU: JSON.parse(command.body).body_ru[1][1],
                descriptionEN: JSON.parse(command.body).body_eng[1][1],
                email: JSON.parse(command.body).body_ru[2][1],
                phone: JSON.parse(command.body).body_ru[3][1],
              };
            }).catch(error => {
              console.error('Ошибка при загрузке изображения:', error);
            });
          });

          console.log('visible');
        }).catch(error => {
          console.error('Ошибка при получении команд:', error);
        });
      },

      getService() {
            try {
                HTTP.get(`/catalog/get_serviceeng`).then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.provideServicesEN[i] = {
                            id: response.data[i].id,
                            img: JSON.parse(response.data[i].image).image[0],
                            textEN: response.data[i].title,
                        }
                    }
                })
                HTTP.get(`/catalog/get_serviceru`).then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.provideServicesRU[i] = {
                            id: response.data[i].id,
                            img: JSON.parse(response.data[i].image).image[0],
                            textRU: response.data[i].title,
                        }
                    }
                })
            } catch (e) {

            }
        },
        getDevice() {
            try {
              HTTP.get(`/catalog/get_by_categoryVideo_monitoring`).then(response => {
                Promise.all(
                    response.data.map(item => {
                      let parsedImage = JSON.parse(item.image).image[0];

                      const formData = new FormData();
                      formData.append('image', parsedImage);

                      return HTTP.post('/get_image/download_image', formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        responseType: 'blob'
                      }).then(postResponse => {
                        const url = URL.createObjectURL(postResponse.data);

                        return {
                          id: item.id,
                          title: item.title,
                          category: item.category,
                          image: url,
                          title_transcription: item.title_transcription,
                        };
                      });
                    })
                )
                    .then(results => {
                      this.largeDevices = results;
                    })
                    .catch(err => {
                      console.error(err);
                    });
              }).catch(err => {
                console.error(err);
              });

            } catch (err) {

            }
        },
    },
    watch: {
        watchComponentTeam(newQuestion, oldQuestion) {
            if (newQuestion) {
                this.getCommands()
            }
        },
        watchComponentService(newQuestion, oldQuestion) {
            if (newQuestion) {
                this.getService()
            }
        },
        watchComponentDevice(newQuestion, oldQuestion) {
            if (newQuestion) {
                this.getDevice()
            }
        }
    },

    setup() {

        useHead({
            title: `ООО «Трансмаш» - горно-шахтное оборудование`,
            meta: [
                {
                    name: `description`,
                    content: `Выбирая горно-шахтное оборудование у нас, вы можете быть уверены в его качестве и надежности. Мы постоянно совершенствуем нашу продукцию, чтобы соответствовать самым высоким требованиям наших клиентов`
                },
                {
                    name: `keywords`,
                    content: `трансмаш, шахта, оборудование для шахт, горно-шахтное оборудование, производство, надежность, долговечность, эффективность, технологии, инновации, качество, услуги, установка, обслуживание, ремонт, сервис, поддержка`
                }
            ]

        })


    },
}
</script>

<style scoped>

.slides-container {
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /*scroll-snap-type: y proximity;*/

}

.slide {
    width: 100vw;
    height: 100vh;

    background-color: black;

    position: relative;
    /* border: solid 1px red; */
    display: flex;

    overflow: hidden;
    /*scroll-snap-align: center;*/
}

div {
    /*scroll-snap-align: center;*/
}
</style>
