<template>
  <li class="container">
    <div class="subContainer" v-if="Object.keys(newsObject)?.length !== 0">
      <div class="containerImage">
        <img class="imageBlur" :src=newsObject.image alt="news_blur_image">
        <img class="image" :src=newsObject.image alt="news_image">
      </div>

      <div class="containerText" >
        <h2>{{ $store.state.lang == 'en-EN' ? JSON.parse(newsObject.title)?.title_eng : JSON.parse(newsObject.title)?.title_ru }}</h2>
        <p class="paragraphStyle">{{$store.state.lang == 'en-EN' ? JSON.parse(newsObject.body).body_eng[0][1]?.toString() : JSON.parse(newsObject.body).body_ru[0][1]?.toString()}} </p>
        <div class="subContainerRow">
          <router-link :to="`/news/${newsObject.title_transcription.replaceAll(' ', '_')}?id=` + newsObject.id"  class="router"
                        
          >
              {{$t('Buttons.more')}}
            
          </router-link>
          <p class="date">
            {{
            newsObject.created_at?.toString().split(' ')[0].split('-').join('/') + ' '
          + newsObject.created_at?.toString().split(' ')[1].split(":")[0] + ':'
          + newsObject.created_at?.toString().split(' ')[1].split(":")[1]
            }}
          </p>
        </div>
        <div class="delete-button-container" v-if="user == 'admin'"> 
            <router-link :to="`/editNews/${newsObject.id}`">
              <button class="delete-button"> 
              </button>
            </router-link>

            <button class="delete-button" @click="deleteNews(newsObject.id)"> 
            </button>
        </div>
      </div>
    </div>

    <SceletonNewsFullPreview v-else/>

  </li>
</template>

<script>
import News from "@/pages/AdditionalPages/News.vue";
import {HTTP} from "@/plugins/http-common";
import SceletonNewsFullPreview from "@/components/Sceletons/SceletonNewsFullPreview.vue";

export default {
  name: "NewsFullPreview",
  components: {News, SceletonNewsFullPreview},
  props: {

    newsObject: {
      type: Object,
    },

    user: {
      default: 'user',
    }
  },

  data(){
    return {

    }
  },

  methods: {
  deleteNews(id){
    HTTP.delete('/news/delete_news/' + id,
    {headers: {
        'Content-Type': 'application/json'
    }})
    .then(function (response) {
        location.reload()
    }).catch(() => {
        this.wrongData = true;
    })
  }
},

}

</script>

<style scoped>
  .container {
    width: 100vw;
    scroll-snap-align: center;
    color: var(--text);
    padding: 35px 0;
    display: flex;
    justify-content: center;
  }
  .containerImage {
    width: 500px;
    height: 400px;
    border-radius: 5px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imageBlur {
    width: 100%;
    height: 100%;
    position: absolute;
    filter: blur(20px);
    background-size: cover;
    transform: scale(1.2);
    background-position-y: center;
  }
  .containerText a {
    color: var(--blue);
    font-size: 20px;
  }
  h2 {
    font-size: 25px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .paragraphStyle {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /*height: 200px;*/
  }
  .containerText p {
    font-size: 20px;
  }
  .date {
    color: #808080;
    right: 0px;
  }
  .containerText a, .date {
    position: absolute;
    bottom: 0;
  }
  .subContainer {
    max-width: 1300px;
    width: 100%;

    height: 100%;
    display: flex;
    column-gap: 20px;
    justify-content: center;
  }
  .containerText {
    display: flex;
    position: relative;
    flex-direction: column;
    row-gap: 20px;
    width: 400px;
  }
  .image {
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: 2;
  }
  .subContainerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .delete-button-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 130px;
  left: 450px;
  z-index: 4 !important;
  height: 120px;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
  box-sizing: border-box;
}

.delete-button {
  width: 32px;
  height: 32px;

  cursor: pointer;

  background: white;
  font-size: 24px;
  font-family: monospace;

  border-radius: 5px;
  border: solid 1px #ccc;

}

.delete-button:nth-child(1){
  background-image: url('./ui/assets/pencil.png');
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.delete-button:nth-child(2){
  background-image: url('./ui/assets/delete.png');
  background-size: 22px;
  background-position: center;
  background-repeat: no-repeat;
}

  @media (max-width: 900px){
    .subContainer {
      flex-direction: column;
      align-items: center;
    }

    .image{
      width: 90%;
    }

    .containerText{
      width: 90%;
      padding-top: 4vh;
    }

    .subContainerRow{
      margin-top: 20px;
    }
  }

  @media (max-width: 450px){
    .image{
      width: 90%;
      height: 300px;
    }

    .containerText{
      width: 90%;
    }

    .containerText p {
      font-size: 16px;
    }
    
    

    .router{
      font-size: 20px;
    }

    .subContainerRow{
      margin-top: 10px;
    }
  }

</style>